import React, { useEffect, useMemo } from 'react';
import uniqid from 'uniqid';

import Icons from 'assets/icons';

import Heading from 'components/partials/heading/heading';
import FlashNotification from 'components/partials/flash-notification/flash-notification';
import AdsBlock from 'components/partials/ads-block/ads-block';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Loader from 'components/partials/loader/loader';
import ReportSection from 'containers/user/reports/report-section/report-section';
import useProductTypesDictionary from 'containers/user/reports/use-product-types-dictionary';
import useClientProducts from 'containers/user/reports/use-client-products';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';

import { useReports } from 'state/reports/reports.thunk';
import { history } from 'state/history';

import { REPORT_TYPES } from 'constants/reports';
import { ADS_BLOCKS } from 'constants/ads-blocks';

const Reports = () => {
  const reports = useReports();
  const { clientProducts, loading: clientProductsLoading } =
    useClientProducts();
  const { dictionary: productTypesDictionary, loading: dictionaryLoading } =
    useProductTypesDictionary();

  useEffect(() => {
    reports?.loadIfMissing();
  }, [reports]);

  const navigateToReport = (reportId) => {
    history.push(`/reports/${reportId}`);
  };

  const handleScheduleCall = () => {
    window.location.href = 'mailto:clientsupport@marketviewedu.com';
  };

  const { filteredReports, productTypes, missingAdTypes } = useMemo(() => {
    if (!reports?.data)
      return { filteredReports: [], productTypes: [], missingAdTypes: [] };

    const types = [
      ...new Set(reports.data.map((report) => report.productType || 'Other')),
    ];
    const sortedTypes = types.toSorted((a, b) => a.localeCompare(b));

    const missing = [];
    const hasRetentionView = clientProducts.some(
      (product) => product.type === REPORT_TYPES.RETENTION_VIEW
    );
    const hasFinancialView = clientProducts.some(
      (product) => product.type === REPORT_TYPES.FINANCIAL_AID_VIEW
    );

    if (!hasRetentionView) {
      missing.push(ADS_BLOCKS.RETENTION.type);
    }

    if (!hasFinancialView) {
      missing.push(ADS_BLOCKS.FINANCE.type);
    }

    return {
      filteredReports: reports.data,
      productTypes: sortedTypes,
      missingAdTypes: missing,
    };
  }, [reports?.data, clientProducts]);

  const isLoading =
    reports?.loading || dictionaryLoading || clientProductsLoading;

  const renderAdsBlocks = () => {
    if (missingAdTypes.length === 0) return null;

    const isFullWidth = missingAdTypes.length === 1;

    const blocksToRender = Object.values(ADS_BLOCKS).filter((block) =>
      missingAdTypes.includes(block.type)
    );

    return (
      <div className="flex flex-wrap gap-4 mt-10">
        {blocksToRender.map((block) => (
          <AdsBlock
            key={block.id}
            title={block.title}
            description={block.description}
            buttonText={block.buttonText}
            backgroundClass={isFullWidth ? block.bgLarge : block.bgSmall}
            isFullWidth={isFullWidth}
            onButtonClick={handleScheduleCall}
          />
        ))}
      </div>
    );
  };

  const getReportsByType = (productType) =>
    filteredReports.filter(
      (report) => (report.productType || 'Other') === productType
    );

  return (
    <ResponsiveWrapper customClass="mt-8">
      <Heading variant="h2">Reports</Heading>

      <FlashNotification customClass="mt-2 mb-4" />

      {!reports?.data?.length && (
        <EmptySpaceContent
          image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
          text="There are no reports to show yet."
          customClass="max-h-[400px] mx-auto mt-5"
        />
      )}

      {isLoading ? (
        <Loader loading={true} customClass="!bg-transparent" />
      ) : (
        <>
          {productTypes.map((productType) => (
            <ReportSection
              key={uniqid()}
              productType={productType}
              reports={getReportsByType(productType)}
              dictionary={productTypesDictionary}
              navigateToReport={navigateToReport}
              isDefaultOpen={true}
            />
          ))}

          {renderAdsBlocks()}
        </>
      )}
    </ResponsiveWrapper>
  );
};

export default Reports;

import { useState, useEffect, useCallback, useRef } from 'react';

/**
 * Custom hook to dynamically calculate and manage table height based on viewport
 *
 * @param {Array} dependencies - Array of dependencies that should trigger height recalculation
 * @returns {Object} Object containing:
 *   - tableHeight {string} - CSS height value
 *   - tableContainerRef {React.RefObject} - Ref to attach to table container
 */
export const useTableHeight = (dependencies = []) => {
  const [tableHeight, setTableHeight] = useState('auto');
  const tableContainerRef = useRef(null);

  const calculateTableHeight = useCallback(() => {
    if (!tableContainerRef.current) return;

    const windowHeight = window.innerHeight;
    const globalFooterHeight =
      document.querySelector('.global-footer')?.clientHeight || 0;
    const tableContainerRect =
      tableContainerRef.current.getBoundingClientRect();
    const availableHeight =
      windowHeight - tableContainerRect.top - globalFooterHeight - 24;

    setTableHeight(`${Math.max(300, availableHeight)}px`);
  }, []);

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener('resize', calculateTableHeight);

    return () => window.removeEventListener('resize', calculateTableHeight);
  }, [calculateTableHeight]);

  useEffect(() => {
    calculateTableHeight();
  }, [...dependencies, calculateTableHeight]);

  return { tableHeight, tableContainerRef };
};

export default useTableHeight;

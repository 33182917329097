import React, {useEffect, useState} from 'react';

import Tile from 'components/shared/others/tile/tile';
import PowerBiReport from 'components/shared/others/power-bi-report/power-bi-report';
import NotificationMessage from 'components/partials/notification/notification';

import {useReports} from 'state/reports/reports.thunk';

import {REPORT_TYPES} from 'constants/reports';

const ChartTile = ({ report, onError }) => (
  <Tile customClass="w-full !p-0">
    {!report ? (
      'Unavailable'
    ) : (
      <PowerBiReport
        onError={onError}
        reportId={report.powerBiId}
        customClass="w-full h-[1098px]"
      />
    )}
  </Tile>
);

const HomeReportTiles = () => {
  const [error, setError] = useState();
  const reports = useReports();

  useEffect(() => {
    reports?.loadIfMissing();
    // eslint-disable-next-line
  }, []);

  const handleError = (error) => {
    setError(error);

    return error;
  };

  const dashboard_1 = Array.isArray(reports?.data)
    ? reports.data.find(
        (report) => report?.powerBiReportType === REPORT_TYPES.DASHBOARD_1
      )
    : null;

  return error ? (
    <NotificationMessage
      customClass="mb-6"
      heading={error.data.title}
      variant="error"
      message={`Error loading reports (${error.data.status} ${error.data.title})`}
    />
  ) : (
    <>
      <div className="flex flex-col md:flex-row w-full min-h-[300px]">
        <ChartTile report={dashboard_1} onError={handleError} />
      </div>
    </>
  );
};

export default HomeReportTiles;

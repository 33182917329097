import { useState, useEffect } from 'react';
import api from 'api';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

const useClientProducts = () => {
  const [clientProducts, setClientProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useCurrentUser();

  useEffect(() => {
    const fetchClientProducts = async () => {
      if (!currentUser?.data?.client?.id || !currentUser?.data?.oktaUserId)
        return;

      setLoading(true);
      setError(null);

      try {
        const response = await api.getClientProducts({
          userId: currentUser.data.oktaUserId,
          clientId: currentUser.data.client.id,
        });

        if (response?.data) {
          setClientProducts(response.data);
        }
      } catch (error) {
        console.error('Error fetching client products:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientProducts();
  }, [currentUser?.data?.client?.id, currentUser?.data?.oktaUserId]);

  return {
    clientProducts,
    loading,
    error,
  };
};

export default useClientProducts;

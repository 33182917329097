import { renderConfirmationModal } from 'helpers/confirmation-render';
import Icons from 'assets/icons';

export const getActionMenuItems = ({ handleDelete, reportId, reportName }) => [
  {
    label: 'Delete report',
    icon: <Icons.Delete customClass="text-red-04 mr-2" />,
    isDelete: true,
    onClick: () =>
      renderConfirmationModal({
        headerText: `Are you sure you want to delete the ${reportName} report?`,
        onConfirmCallback: () => {
          handleDelete(reportId);
        },
        onConfirmButtonLabel: 'Delete report',
        text: `Deleting the ${reportName} report will remove it from the list of available reports and unlink it from associated products.`,
      }),
  },
];

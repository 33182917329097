import { useCallback } from 'react';
import { USER_ASSIGNMENT_STATUS } from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.constants';
import {
  determineUserAssignmentStatus,
  updateUserAccess,
  updateAllUsersAccess,
} from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.utils';
import * as reportAccessService from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.helpers';

/**
 * Custom hook that provides actions for managing report access settings
 *
 * @param {Array} reports - List of reports
 * @param {Function} setReports - State setter for reports
 * @param {Object} reportUsers - Map of report IDs to arrays of users with access
 * @param {Function} setReportUsers - State setter for reportUsers
 * @param {string} userId - Current user's ID
 * @param {string} clientId - Client ID
 * @param {boolean} loading - Current loading state
 * @param {Function} setLoading - Function to update loading state
 * @returns {Object} Object containing:
 *   - handleReportAccessChange {Function} - Updates access for all users to a report
 *   - handleDataIssueChange {Function} - Updates data issue flag for a report
 *   - handleUserAccessChange {Function} - Updates access for a specific user to a report
 */
export const useAccessActions = (
  reports,
  setReports,
  reportUsers,
  setReportUsers,
  userId,
  clientId,
  loading,
  setLoading
) => {
  /**
   * Changes access to a report for all users
   *
   * @param {string} reportId - ID of the report to update
   * @param {boolean} value - Whether to grant (true) or revoke (false) access
   */
  const handleReportAccessChange = useCallback(
    async (reportId, value) => {
      if (loading) return;

      setLoading(true);
      try {
        const currentReport = reports.find((report) => report.id === reportId);

        if (!currentReport) return;

        const newStatus = value
          ? USER_ASSIGNMENT_STATUS.ALL_ASSIGNED
          : USER_ASSIGNMENT_STATUS.NONE_ASSIGNED;

        const {
          data: { userAssignmentStatus },
        } = await reportAccessService.updateReportAccess({
          userId,
          clientId,
          reportId,
          value: {
            userAssignmentStatus: newStatus,
            hasDataIssue: currentReport.hasDataIssue,
          },
        });

        setReports((prevReports) =>
          prevReports.map((report) =>
            report.id === reportId
              ? { ...report, userAssignmentStatus }
              : report
          )
        );

        if (reportUsers[reportId]) {
          setReportUsers((prev) => ({
            ...prev,
            [reportId]: updateAllUsersAccess(prev[reportId], value),
          }));
        }
      } catch (error) {
        console.error('Error updating report access:', error);
      } finally {
        setLoading(false);
      }
    },
    [
      reports,
      reportUsers,
      userId,
      clientId,
      loading,
      setLoading,
      setReports,
      setReportUsers,
    ]
  );

  /**
   * Changes the data issue flag for a report
   *
   * @param {string} reportId - ID of the report to update
   * @param {boolean} value - Whether the report has a data issue
   */
  const handleDataIssueChange = useCallback(
    async (reportId, value) => {
      if (loading) return;

      setLoading(true);
      try {
        const currentReport = reports.find((report) => report.id === reportId);

        if (!currentReport) return;

        await reportAccessService.updateReportAccess({
          userId,
          clientId,
          reportId,
          value: {
            userAssignmentStatus: currentReport.userAssignmentStatus,
            hasDataIssue: value,
          },
        });

        setReports((prevReports) =>
          prevReports.map((report) =>
            report.id === reportId ? { ...report, hasDataIssue: value } : report
          )
        );
      } catch (error) {
        console.error('Error updating data issue flag:', error);
      } finally {
        setLoading(false);
      }
    },
    [reports, userId, clientId, loading, setLoading, setReports]
  );

  /**
   * Changes access to a report for a specific user
   *
   * @param {string} reportId - ID of the report to update
   * @param {Object} userData - User data containing:
   *   - userId {string} - ID of the user
   *   - hasAccess {boolean} - Whether to grant (true) or revoke (false) access
   */
  const handleUserAccessChange = useCallback(
    async (reportId, userData) => {
      if (loading) return;

      setLoading(true);
      try {
        const currentReport = reports.find((report) => report.id === reportId);
        const currentUsers = reportUsers[reportId] || [];

        if (!currentReport) return;

        if (userData.hasAccess) {
          await reportAccessService.assignReportToUser({
            userId,
            reportId,
            id: userData.userId,
          });
        } else {
          await reportAccessService.unassignReportFromUser({
            userId,
            reportId,
            id: userData.userId,
          });
        }

        const updatedUsers = updateUserAccess(
          currentUsers,
          userData.userId,
          userData.hasAccess
        );

        const newStatus = determineUserAssignmentStatus(updatedUsers);

        if (currentReport.userAssignmentStatus !== newStatus) {
          const {
            data: { userAssignmentStatus },
          } = await reportAccessService.updateReportAccess({
            userId,
            clientId,
            reportId,
            value: {
              userAssignmentStatus: newStatus,
              hasDataIssue: currentReport.hasDataIssue,
            },
          });

          setReports((prevReports) =>
            prevReports.map((report) =>
              report.id === reportId
                ? { ...report, userAssignmentStatus }
                : report
            )
          );
        }

        setReportUsers((prev) => ({
          ...prev,
          [reportId]: updatedUsers,
        }));
      } catch (error) {
        console.error('Error updating user report access:', error);
      } finally {
        setLoading(false);
      }
    },
    [
      reports,
      reportUsers,
      userId,
      clientId,
      loading,
      setLoading,
      setReports,
      setReportUsers,
    ]
  );

  return {
    handleReportAccessChange,
    handleDataIssueChange,
    handleUserAccessChange,
  };
};

export default useAccessActions;

import React from 'react';

const ReportAssignmentHeader = () => {
  const headerCellStyle =
    'bg-gray-00 text-blue-03 text-xs tracking-[1.2px] pl-4 border-r-2 border-white font-semibold py-3 px-4';

  return (
    <div className="overflow-hidden rounded-lg">
      <table className="w-full table-fixed">
        <thead>
          <tr>
            <th className={`${headerCellStyle} px-0 w-[5%]`}></th>
            <th className={`${headerCellStyle} w-[55%] text-left`}>
              REPORT ASSIGNMENT
            </th>
            <th className={`${headerCellStyle} w-[20%]`}>REPORT ACCESS</th>
            <th className={`${headerCellStyle} w-[20%]`}>
              DATA ISSUE VISIBILITY
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default React.memo(ReportAssignmentHeader);

import React from 'react';
import { Controller } from 'react-hook-form';

import CustomInput from 'components/partials/custom-text-input/custom-text-input';
import Textarea from 'components/partials/textarea/textarea';
import CustomSelect from 'components/partials/custom-select/custom-select';

const ProductForm = ({ control, disabled, productTypes, clearErrors }) => (
  <div className="flex h-full gap-8 items-baseline">
    <div className="flex flex-col gap-3 w-1/2">
      <Controller
        name="name"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState: { error } }) => (
          <CustomInput
            {...field}
            label="Product name"
            placeholder="Product name..."
            disabled={disabled}
            errorsNames={error}
            labelInTop={true}
            required={true}
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState: { error } }) => {
          const transformedOptions = productTypes.map((pt) => ({
            value: pt.name,
            label: pt.description,
          }));

          return (
            <CustomSelect
              {...field}
              label="Product type"
              disabled={disabled}
              options={transformedOptions}
              labelInTop={true}
              fieldError={
                error && { ...error, message: 'This field is required' }
              }
              required={true}
              onChange={(selectedOption) => {
                field.onChange(selectedOption.value);
              }}
              value={field.value}
              clearInputErr={() => clearErrors('type')}
            />
          );
        }}
      />
    </div>

    <div className="flex flex-col gap-3 w-1/2">
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            label="Product description"
            placeholder="Product description..."
            disabled={disabled}
          />
        )}
      />
    </div>
  </div>
);

export default ProductForm;

import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';

const Toggle = ({
  onToggleChange,
  isToggleOn,
  toggleText,
  disabled = false,
}) => {
  const id = `toggle-${uniqid()}`;

  return (
    <div
      className={classNames('flex items-center', { 'opacity-50': disabled })}
    >
      {toggleText && <span className="mr-2">{toggleText}</span>}
      <label
        htmlFor={id}
        className={classNames('relative inline-flex items-center', {
          'cursor-pointer': !disabled,
        })}
      >
        <input
          id={id}
          type="checkbox"
          checked={isToggleOn}
          onChange={(e) => {
            if (!disabled) {
              onToggleChange(e.target.checked);
            }
          }}
          disabled={disabled}
          className="sr-only peer"
          aria-label={toggleText || 'Toggle'}
        />
        <div
          className="peer
          w-11 h-6 
          rounded-full 
          bg-gray-07 
          dark:bg-gray-07
          peer-checked:after:translate-x-full 
          after:absolute 
          after:top-[1.9px] 
          after:left-[2px] 
          after:bg-white 
          after:rounded-full 
          after:h-5 after:w-5 
          after:transition-all
          peer-focus-visible:outline
          peer-checked:bg-yellow-03"
        ></div>
      </label>
    </div>
  );
};

export default Toggle;

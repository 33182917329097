import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import api from 'api';

import CustomButton from 'components/partials/custom-button/custom-button';
import Heading from 'components/partials/heading/heading';
import Search from 'components/partials/custom-search/custom-search';
import TableComponent from 'components/partials/table-component/table-component';
import Loader from 'components/partials/loader/loader';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import { getReportsTableSettings } from 'containers/admin/reports/reports-table-settings';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useReportsList } from 'state/reports-list/reports-list-thunk';

import { usersReducer } from 'containers/admin/reducer';
import { SEARCH_CHANGE, PAGE_CHANGE } from 'containers/admin/actions';

import Icons from 'assets/icons';

const initialState = {
  search: '',
  pageIndex: 0,
};

const ReportsAdminPage = ({ clientId }) => {
  const { load } = useReportsList();

  const [initialLoading, setInitialLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isErrorOccured, setIsErrorOccured] = useState(false);
  const [products, setProducts] = useState([]);
  const [productDictionary, setProductDictionary] = useState([]);

  const [state, dispatch] = useReducer(usersReducer, initialState);

  const [{ pageSize, totalRecords }, setAmount] = useState({
    pageSize: 10,
    totalRecords: 0,
  });

  const [sortingParams, setSortingParams] = useState([
    { id: 'title', desc: false },
  ]);

  const handleSearch = useCallback((payload) =>
    dispatch({ type: SEARCH_CHANGE, payload })
  );

  const handlePagination = useCallback((payload) =>
    dispatch({ type: PAGE_CHANGE, payload })
  );

  const currentUser = useCurrentUser();

  const columns = getReportsTableSettings(products, productDictionary);

  const { search, pageIndex } = state;

  useEffect(() => {
    const getProductsDictionary = async () => {
      try {
        const response = await api.dictionary({
          userId: currentUser.data.oktaUserId,
          type: 'PRODUCT_TYPE',
        });

        if (response?.data) {
          setProductDictionary(response.data);
        }
      } catch (e) {
        console.error('Error while fetching products dictionary', e);
      }
    };

    getProductsDictionary();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await api.getProducts({
          userId: currentUser.data.oktaUserId,
        });

        if (response?.data) {
          setProducts(response.data);
        }
      } catch (e) {
        console.error('Error while fetching products', e);
      }
    };

    getProducts();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    load({
      pageIndex,
      pageSize,
      sortBy: sortingParams,
      filters: {
        clientId,
        title: { contains: search },
      },
    })
      .then((response) => {
        setData(response?.data ?? []);

        if (response?.pagination) {
          const { totalRecords: newTotalRecords } = response.pagination;

          setAmount({ pageSize, totalRecords: newTotalRecords });
        }
      })
      .catch(() => {
        setIsErrorOccured(true);
      })
      .finally(() => {
        setIsLoading(false);
        setInitialLoading(false);
      });
  }, [pageIndex, pageSize, search, clientId, sortingParams]);

  const renderContent = () => {
    if (
      (!isErrorOccured && data.length > 0) ||
      (isLoading && !initialLoading)
    ) {
      return (
        <TableComponent
          columns={columns}
          data={data}
          pageIndex={pageIndex}
          isLoading={isLoading && !initialLoading}
          pageSize={pageSize}
          setPagination={handlePagination}
          setAmount={setAmount}
          sortingParams={sortingParams}
          setSortingParams={setSortingParams}
          totalRecords={totalRecords}
          customHeight="max-h-[50vh]"
        />
      );
    }

    if (!isLoading && data.length === 0) {
      return (
        <EmptySpaceContent
          image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
          text="There are no reports to show yet."
        />
      );
    }

    return null;
  };

  return (
    <ResponsiveWrapper>
      <>
        <div className="flex justify-between items-center my-5">
          <Loader loading={initialLoading} />

          <Heading variant="h1">Reports</Heading>
          <RouterLink to="/admin/reports/new">
            <CustomButton
              variant="primary"
              size="lg"
              iconBefore={
                <Icons.Plus alt="PlusIcon" height="100%" width="14" />
              }
            >
              Add a Report
            </CustomButton>
          </RouterLink>
        </div>

        <Search {...{ search, setSearch: handleSearch, customClass: 'mb-5' }} />

        <div className="relative">{renderContent()}</div>
      </>
    </ResponsiveWrapper>
  );
};

export default ReportsAdminPage;

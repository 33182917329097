import React, { useState } from 'react';

import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';
import Loader from 'components/partials/loader/loader';
import { CustomSelect as Select } from 'components/partials/custom-select/custom-select';

import GoalItem from 'containers/user/goals/goal-item';
import GoalContactItem from 'containers/user/goals/goal-contact-item';

import { generateYearOptions } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-filters/goals-matrix-filters';

import { getCurrentYear } from 'containers/admin/clients/client/client-details';
import useGoals from 'containers/user/goals/use-goal';
import { ROWS } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-tab.constants';

const MyGoals = () => {
  const currentYear = getCurrentYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const years = generateYearOptions({ futureYears: 1, pastYears: 1 });

  const { loading, goals } = useGoals(selectedYear);

  const groupGoalsByCategory = (goalsList) =>
    goalsList.reduce((acc, goal) => {
      const category = goal.category;

      if (category === 'Other') return acc;

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(goal);

      return acc;
    }, {});

  const renderGoalsList = () => {
    const goalsByCategory = groupGoalsByCategory(goals);
    const categories = Object.keys(goalsByCategory);

    const orderMap = Object.values(ROWS).reduce((map, key, index) => {
      map[key] = index;

      return map;
    }, {});

    const sortedCategories = categories.toSorted(
      (a, b) => orderMap[a] - orderMap[b]
    );

    return (
      <div className="mt-4">
        {sortedCategories.map((category) => (
          <div
            key={category}
            className="mb-6 flex flex-col mobile:items-center tablet:items-start"
          >
            <Heading variant="h3" customClass="font-bold">
              {category} category
            </Heading>
            <div className="flex flex-wrap gap-4 mt-2 mobile:justify-center tablet:justify-start">
              {goalsByCategory[category].map((goal) => (
                <GoalItem key={goal.id} goal={goal} />
              ))}
            </div>
          </div>
        ))}
        <GoalContactItem />
      </div>
    );
  };

  return (
    <ResponsiveWrapper customClass="mt-8 xs:max-w-[900px]">
      <Loader loading={loading} fullScreen={false} />
      <div className="flex items-center mb-6 gap-4 mobile:justify-center tablet:justify-start">
        <Heading variant="h2">My Goals</Heading>
        <Select
          options={years}
          value={years.find((year) => year.value === selectedYear)}
          onChange={(option) => setSelectedYear(option.value)}
          customSelectWrapperClass="!w-28 mr-4"
          extraBasicStyles={{ padding: 0, height: '2' }}
        />
      </div>
      <p className="text-lg mobile:text-center mobile:w-full md:w-1/2 tablet:text-left">
        At MARKETview, your goals are our goals. To ensure they remain top of
        mind, they are always the focal point of our regular consultations
        together. They will also serve as guiding light in identifying, tracking
        and assessing the progress of your Critical Elements as they align with
        each specific objective that you want to achieve.
      </p>

      {renderGoalsList()}
    </ResponsiveWrapper>
  );
};

export default MyGoals;

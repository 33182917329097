import { useState, useEffect } from 'react';
import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

const useProductTypesDictionary = () => {
  const [dictionary, setDictionary] = useState({});
  const [loading, setLoading] = useState(true);
  const currentUser = useCurrentUser();

  useEffect(() => {
    const fetchDictionary = async () => {
      setLoading(true);
      try {
        const response = await api.dictionary({
          userId: currentUser.data.oktaUserId,
          type: 'PRODUCT_TYPE',
        });

        if (response?.data) {
          setDictionary(response?.data);
        }
      } catch (error) {
        console.error('Error fetching product type dictionary:', error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser?.data?.oktaUserId) {
      fetchDictionary();
    }
  }, [currentUser?.data?.oktaUserId]);

  return { dictionary, loading };
};

export default useProductTypesDictionary;

import React from 'react';
import { SecureRoute } from '@okta/okta-react';

import UsersAdminPage from 'containers/admin/users/users-admin-page';

const UsersTab = ({ clientId }) => (
  <SecureRoute
    exact
    path="/admin/clients/:id/users"
    render={({ location }) => {
      const state = location.state;

      return (
        <div className="relative">
          <UsersAdminPage
            clientId={clientId}
            notificationParams={state?.notificationParams}
            isTab={true}
          />
        </div>
      );
    }}
  />
);

export default UsersTab;

import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import CustomSelect from 'components/partials/custom-select/custom-select';

import { useUsers } from 'state/users/users.thunk';

import { mapUsersToSelectOptions } from 'helpers/forms';

import { ASIGNED_CONSULTANTS_FORM_FIELDS } from 'containers/admin/clients/client/forms/asigned-consultants-form/asigned-consultants-form-fields.constants';

const AsignedConsultantsForm = ({ isFormDisabled, control }) => {
  const { load: loadUsers, data: superAdmins } = useUsers();

  useEffect(() => {
    loadUsers({
      pageSize: 9999,
      filters: {
        authority: [
          'ROLE_SUPER_ADMIN',
          'ROLE_FD_PRINCIPAL',
          'ROLE_FD_CLIENT_SERVICE',
          'ROLE_FD_ANALYST',
          'ROLE_INTERNAL_USER',
        ],
      },
    });
  }, []);

  const getConsultants = (optionsKey) => {
    const currentConsultants = superAdmins.filter(
      (user) => user.superAdmin || user[optionsKey]
    );

    return mapUsersToSelectOptions(currentConsultants);
  };

  return (
    <div className="flex flex-col w-3/6">
      {ASIGNED_CONSULTANTS_FORM_FIELDS.map(
        ({ label, name, key, renderComponentProps, optionsKey }) => (
          <Controller
            key={key}
            name={name}
            control={control}
            render={({ field }) => (
              <CustomSelect
                label={label}
                labelInTop
                options={getConsultants(optionsKey)}
                disabled={isFormDisabled}
                disabledBgColor={isFormDisabled && '#F2F2F2'}
                {...field}
                {...renderComponentProps}
              />
            )}
          />
        )
      )}
    </div>
  );
};

export default AsignedConsultantsForm;

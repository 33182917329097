import React, { useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

import PowerBiReport from 'components/shared/others/power-bi-report/power-bi-report';
import Loader from 'components/partials/loader/loader';
import NotificationMessage from 'components/partials/notification/notification';
import Heading from 'components/partials/heading/heading';

import { useReports } from 'state/reports/reports.thunk';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';

const ReportPage = ({ reportId }) => {
  const reports = useReports();
  const [currentReport, setCurrentReport] = useState();
  const [error, setError] = useState(null);

  const location = useLocation();

  const powerbiRelatedReportPageId =
    location.state?.powerbiRelatedReportPageId || null;

  useEffect(() => {
    reports?.loadIfMissing();
  }, [reports]);

  useEffect(() => {
    if (reports.data) {
      setCurrentReport(reports.data.find((rpt) => rpt.id === Number(reportId)));
    }
  }, [reports.data, reportId]);

  const handleReportError = (error) => {
    setError(error);
  };

  return (
    <ResponsiveWrapper>
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Reports', href: '/reports' },
          { label: currentReport?.title, isActive: true },
        ]}
      />
      <div>
        <p className="mt-4 text-xs uppercase text-gray-02 tracking-widest font-text">
          Currently Viewing
        </p>
        <div className="inline-flex">
          <Heading customClass="mr-2" variant="h1">
            {currentReport?.title}
          </Heading>
        </div>
      </div>
      {error && (
        <div className="mt-6">
          <NotificationMessage
            customClass="mt-6"
            heading="Error loading report"
            variant="error"
            message={`There was an error loading this report. (${error?.status} ${error?.statusText})`}
          />
        </div>
      )}
      <div className="mt-6  min-h-[300px] relative">
        {reports.loading ? (
          <Loader {...{ loading: reports.loading }} />
        ) : currentReport ? (
          <PowerBiReport
            reportId={currentReport.powerBiId}
            relatedPageId={powerbiRelatedReportPageId}
            onError={handleReportError}
            style={{ aspectRatio: '1.7/1.1' }}
          />
        ) : (
          <NotificationMessage
            customClass="mt-6"
            heading="Unavailable"
            variant="info"
            message="No report found"
          />
        )}
      </div>
    </ResponsiveWrapper>
  );
};

export default withRouter(ReportPage);

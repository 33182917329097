import React, { useState, useEffect, useCallback, useReducer } from 'react';
import api from 'api';

import Search from 'components/partials/custom-search/custom-search';
import TableComponent from 'components/partials/table-component/table-component';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';
import { usersReducer } from 'containers/admin/reducer';
import { SEARCH_CHANGE, PAGE_CHANGE } from 'containers/admin/actions';
import { getIncludedReportsTableSettings } from 'containers/admin/products/product/included-reports/included-reports-table-settings';
import CustomRadioInput from 'components/partials/custom-radio-input/custom-radio-input';

import { useReportsList } from 'state/reports-list/reports-list-thunk';
import Icons from 'assets/icons';

const initialState = {
  search: '',
  pageIndex: 0,
};

const radioOptions = [
  { label: 'Linked', value: 'linked' },
  { label: 'All available', value: 'all' },
];

const IncludedReports = ({ productId, userId, initialLoading }) => {
  const { load } = useReportsList();

  const [state, dispatch] = useReducer(usersReducer, initialState);
  const { search, pageIndex } = state;

  const [{ pageSize, totalRecords }, setAmount] = useState({
    pageSize: 20,
    totalRecords: 0,
  });

  const [sortingParams, setSortingParams] = useState([
    { id: 'title', desc: false },
  ]);

  const [filterOption, setFilterOption] = useState('linked');

  const [allReports, setAllReports] = useState([]);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = useCallback(
    (payload) => dispatch({ type: SEARCH_CHANGE, payload }),
    []
  );
  const handlePagination = useCallback(
    (payload) => dispatch({ type: PAGE_CHANGE, payload }),
    []
  );

  const loadReports = useCallback(() => {
    setIsLoading(true);
    const filters = { title: search };

    load({
      pageIndex,
      pageSize,
      sortBy: sortingParams,
      filters,
    })
      .then((response) => {
        const fetchedReports = response?.data ?? [];

        setAllReports(fetchedReports);
        if (response?.pagination) {
          const { totalRecords: newTotalRecords } = response.pagination;

          setAmount({ pageSize, totalRecords: newTotalRecords });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [search, pageIndex, pageSize, sortingParams]);

  useEffect(() => {
    if (filterOption === 'linked') {
      setReports(allReports.filter((report) => report.productId == productId));
    } else {
      setReports(
        allReports.filter(
          (report) => !report.productId || report.productId == productId
        )
      );
    }
  }, [filterOption, allReports, productId]);

  useEffect(() => {
    loadReports();
  }, [loadReports]);

  const handleToggleChange = async (report, newToggleValue) => {
    setIsLoading(true);

    const { title, description, powerBiId } = report;

    try {
      const updatedData = {
        title,
        description,
        powerBiId,
        productId: newToggleValue ? productId : null,
      };

      await api.modifyReport({
        userId,
        reportId: report.id,
        reportData: updatedData,
      });
      loadReports();
    } catch (error) {
      console.error('Error updating report', error);
      setIsLoading(false);
    }
  };

  const columns = getIncludedReportsTableSettings(
    productId,
    handleToggleChange
  );

  const renderContent = () => {
    if (reports.length > 0 || (isLoading && !initialLoading)) {
      return (
        <TableComponent
          columns={columns}
          isLoading={isLoading}
          data={reports}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={handlePagination}
          setAmount={setAmount}
          sortingParams={sortingParams}
          setSortingParams={setSortingParams}
          totalRecords={totalRecords}
          customHeight="max-h-[50vh]"
        />
      );
    }

    if (!isLoading && !initialLoading && reports.length === 0) {
      return (
        <EmptySpaceContent
          image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
          text="There are no reports to show."
          customClass="!mt-14 !h-[350px]"
        />
      );
    }

    return null;
  };

  return (
    <>
      <div className="relative">
        <div className="my-4">
          <Search search={search} setSearch={handleSearch} />
        </div>
        <div className="flex gap-5 absolute z-10">
          <div className="font-semibold text-gray-04">Show</div>
          <div className="flex">
            {radioOptions.map((option) => (
              <CustomRadioInput
                key={option.value}
                labelFor={option.value}
                label={option.label}
                radioValue={option.value}
                setAction={(val) => setFilterOption(val)}
                onChange={(val) => setFilterOption(val)}
                value={filterOption}
                customClass="mr-4"
                labelClass={option.colorClass}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="relative mt-4">{renderContent()}</div>
    </>
  );
};

export default IncludedReports;

import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import api from 'api';

import CustomButton from 'components/partials/custom-button/custom-button';
import Heading from 'components/partials/heading/heading';
import TableComponent from 'components/partials/table-component/table-component';
import Loader from 'components/partials/loader/loader';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import { getProductsTableSettings } from 'containers/admin/products/products-table-settings';

import { useProducts } from 'state/products/products.thunk';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

import Icons from 'assets/icons';

const ProductsAdminPage = ({ clientId }) => {
  const { load } = useProducts();

  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorOccured, setIsErrorOccured] = useState(false);
  const [products, setProducts] = useState([]);
  const [productDictionary, setProductDictionary] = useState([]);

  const [sortingParams, setSortingParams] = useState([
    { id: 'name', desc: false },
  ]);

  const currentUser = useCurrentUser();

  const columns = getProductsTableSettings(productDictionary);

  useEffect(() => {
    const getProductsDictionary = async () => {
      try {
        const response = await api.dictionary({
          userId: currentUser.data.oktaUserId,
          type: 'PRODUCT_TYPE',
        });

        if (response?.data) {
          setProductDictionary(response.data);
        }
      } catch (e) {
        console.error('Error while fetching products dictionary', e);
      }
    };

    getProductsDictionary();
  }, []);

  useEffect(() => {
    setIsLoading(true);

    load({
      sortBy: sortingParams,
      pageSize: 1000,
      filters: {
        clientId,
      },
    })
      .then((response) => {
        setProducts(response?.data ?? []);
      })
      .catch(() => {
        setIsErrorOccured(true);
      })
      .finally(() => {
        setIsLoading(false);
        setInitialLoading(false);
      });
  }, [clientId]);

  const renderContent = () => {
    if (
      (!isErrorOccured && products.length > 0) ||
      (isLoading && !initialLoading)
    ) {
      return (
        <TableComponent
          columns={columns}
          data={products}
          sortingParams={sortingParams}
          setSortingParams={setSortingParams}
          customHeight="max-h-[50vh]"
          pagination={false}
          isLoading={isLoading && !initialLoading}
          manualSorting={false}
        />
      );
    }

    if (!isLoading && products.length === 0) {
      return (
        <EmptySpaceContent
          image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
          text="There are no products to show yet."
        />
      );
    }

    return null;
  };

  return (
    <ResponsiveWrapper>
      <>
        <div className="flex justify-between items-center my-5">
          <Loader loading={initialLoading} />
          <Heading variant="h1">MarketView Products</Heading>
          <RouterLink to="/admin/products/new">
            <CustomButton
              variant="primary"
              size="lg"
              iconBefore={
                <Icons.Plus alt="PlusIcon" height="100%" width="14" />
              }
            >
              Add a Product
            </CustomButton>
          </RouterLink>
        </div>

        <div className="relative">{renderContent()}</div>
      </>
    </ResponsiveWrapper>
  );
};

export default ProductsAdminPage;

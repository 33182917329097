import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useReadOnly } from 'hooks/read-only';

import Loader from 'components/partials/loader/loader';
import FlashNotification from 'components/partials/flash-notification/flash-notification';
import TableComponent from 'components/partials/table-component/table-component';
import CustomButton from 'components/partials/custom-button/custom-button';
import Heading from 'components/partials/heading/heading';
import Search from 'components/partials/custom-search/custom-search';
import Notification from 'components/partials/notification/notification.helper';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';

import { usersReducer } from 'containers/admin/reducer';
import { getUsersTableSettings } from 'containers/admin/users/users-table-settings';
import { PAGE_CHANGE, SEARCH_CHANGE } from 'containers/admin/actions';

import { useImpersonation } from 'state/impersonation/impersonation.thunk';
import { useUsers } from 'state/users/users.thunk';

import Icons from 'assets/icons';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const initialState = {
  search: '',
  pageIndex: 0,
};

const UsersAdminPage = ({ clientId, notificationParams, isTab }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(usersReducer, initialState);
  const [data, setData] = useState([]);
  const [{ pageSize, totalRecords }, setAmount] = useState({
    pageSize: 100,
    totalRecords: 0,
  });

  const { search, pageIndex } = state;

  const impersonation = useImpersonation();
  const readOnly = useReadOnly();

  const { load, loadingUsersError } = useUsers();
  const [isLoading, setIsLoading] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);

  const columns = getUsersTableSettings({
    clientId,
    impersonation,
  });

  const [sortingParams, setSortingParams] = useState([
    { id: 'firstName', desc: false },
  ]);

  const handleSearch = useCallback(
    (payload) => dispatch({ type: SEARCH_CHANGE, payload }),
    []
  );

  const handlePagination = useCallback(
    (payload) => dispatch({ type: PAGE_CHANGE, payload }),
    []
  );

  const handleEmptyLink = () => {
    history.push(
      clientId ? `/admin/clients/${clientId}/users/new` : '/admin/users/new'
    );
  };

  useEffect(() => {
    setIsLoading(true);
    load({
      pageIndex,
      pageSize,
      sortBy: sortingParams,
      filters: {
        clientId,
        search: { value: state.search },
      },
    })
      .then((response) => {
        if (response?.data) {
          const users = response.data;
          const updatedData = users.map((user, i) => {
            if (!user.client) {
              users[i].client = { name: '' };
            }

            return user;
          });

          setData(updatedData);
        }

        if (response?.pagination) {
          const { totalRecords: newTotalRecords } = response.pagination;

          setAmount({ pageSize, totalRecords: newTotalRecords });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setInitialLoading(false);
      });
  }, [state, pageSize, sortingParams]);

  useEffect(() => {
    if (loadingUsersError) setData([]);
  }, [loadingUsersError]);

  useEffect(() => {
    if (notificationParams)
      Notification.flash(
        notificationParams.heading,
        notificationParams.variant,
        notificationParams.message
      );
  }, [notificationParams]);

  const renderContent = () => {
    if (initialLoading) {
      return (
        <Loader
          loading={initialLoading}
          fullScreen={false}
          customClass={isTab ? '!bg-white' : undefined}
          overlayClassName={isTab ? '!opacity-0' : undefined}
        />
      );
    }
    if (data.length > 0 || (isLoading && !initialLoading)) {
      return (
        <div className="relative">
          <TableComponent
            columns={columns}
            data={data}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPagination={handlePagination}
            setAmount={setAmount}
            sortingParams={sortingParams}
            setSortingParams={setSortingParams}
            totalRecords={totalRecords}
            isLoading={isLoading}
          />
        </div>
      );
    }

    return (
      <EmptySpaceContent
        image={
          clientId ? (
            <Icons.EmptyImage customClass="w-[210px] h-[210px]" />
          ) : (
            <Icons.EmptyImageAll customClass="w-[210px] h-[210px]" />
          )
        }
        text={
          clientId
            ? 'This account has no users yet.'
            : 'Sorry, no results found. Please try a different keyword.'
        }
        linkShow={clientId ? !readOnly : null}
        linkText={clientId ? 'Add a new user' : null}
        onClick={handleEmptyLink}
      />
    );
  };

  return (
    <ResponsiveWrapper>
      <div className="flex items-center justify-between my-5">
        <Heading variant={clientId ? 'h2' : 'h1'}>Users</Heading>
        {!readOnly && (
          <RouterLink
            to={
              clientId
                ? `/admin/clients/${clientId}/users/new`
                : '/admin/users/new'
            }
          >
            <CustomButton
              variant="primary"
              size="lg"
              iconBefore={
                <Icons.Plus alt="PlusIcon" height="100%" width="14" />
              }
            >
              Add a User
            </CustomButton>
          </RouterLink>
        )}
      </div>

      <Search {...{ search, setSearch: handleSearch, customClass: 'mb-5' }} />

      <FlashNotification customClass="mb-4" />

      {renderContent()}
    </ResponsiveWrapper>
  );
};

export default UsersAdminPage;

import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import { withAuth } from '@okta/okta-react';

import { useOkta } from 'utils/okta/use-okta';

import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import 'containers/login/okta-widget/okta-theme.css';

const OktaSignInWidget = () => {
  const oktaAuth = useOkta();
  const widgetRef = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    const el = containerRef.current;

    if (!el) return;

    widgetRef.current = new OktaSignIn({
      baseUrl: oktaAuth.options.url,
      clientId: oktaAuth.options.clientId,
      redirectUri: oktaAuth.options.redirectUri,
      useClassicEngine: true,
      authParams: {
        issuer: oktaAuth.options.issuer,
        responseType: ['code'],
        scopes: ['openid', 'profile', 'email'],
      },
      features: {
        autoPush: true,
        rememberMe: true,
        registration: false,
        hideSignOutLinkInMFA: true,
      },
      brandName: 'MARKETview',
      logo: null,
      i18n: {
        en: {
          'primaryauth.title': 'Log In',
          'primaryauth.submit': 'Sign In',
          autoPush: 'Send push automatically next time',
          'enroll.choices.description.generic':
            'MARKETview requires multifactor authentication to add an additional layer of security when signing in to your account.',
          'enroll.totp.setupApp':
            'Launch the {0} application on your mobile device and select Add an account.',
        },
      },
    });

    const successCallback = (res) => {
      if (res.status === 'SUCCESS') {
        oktaAuth.token.getWithRedirect({
          responseType: ['code'],
          scopes: ['openid', 'profile', 'email'],
        });
      }
    };

    const errorCallback = (err) => {
      console.error('SignIn Widget Error: ', err);
    };

    widgetRef.current.renderEl({ el }, successCallback, errorCallback);

    return () => {
      if (widgetRef.current) {
        widgetRef.current.remove();
      }
    };
  }, [oktaAuth]);

  return <div ref={containerRef} />;
};

export default withAuth(OktaSignInWidget);

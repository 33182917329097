import React, { createContext, useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Heading from 'components/partials/heading/heading';
import FlashNotification from 'components/partials/flash-notification/flash-notification';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import Loader from 'components/partials/loader/loader';
import ActionMenu from 'components/partials/action-menu/action-menu';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import { getStatusLabel } from 'components/partials/table-component/helpers/get-status-label';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useUsers } from 'state/users/users.thunk';
import { useImpersonation } from 'state/impersonation/impersonation.thunk';
import { useReadOnly } from 'hooks/read-only';

import UserForm from 'containers/admin/users/user/forms/user-form';
import {
  onUserFormSubmit,
  getBreadcrumbsForUserPage,
  getRedirectPathForUser,
} from 'containers/admin/users/user/user-profile/user-profile.helpers';
import { USER_PROFILE_FORM_VALIDATION_SCHEMA } from 'containers/admin/users/user/user-profile/user-profile.constants';
import { USERS_PROFILE_STATUSES } from 'containers/admin/users/user/user-profile/user-profile.constants';

const CAMPUS_USER = 'Campus User';
const INTERNAL_USER = 'Internal User';

export const UserProfileContext = createContext({
  control: {},
  errors: {},
  clearErrors: () => {},
  resetField: () => {},
});

const UserProfile = ({
  pageName = 'Add new user',
  currentClient,
  user,
  isCurrentUserProfile,
  isFormDisabled = false,
  setIsFormActive,
  loading,
  setLoading,
  actionMenuItems = [],
  isImpersonatingUser,
  defaultValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    confirmEmail: '',
    role: 'user',
    client: null,
  },
}) => {
  const [loader, setLoader] = useState(false);
  const [isNotNewUser, setIsNotNewUser] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');

  const currentUser = useCurrentUser();
  const users = useUsers();

  const history = useHistory();
  const readOnly = useReadOnly();

  const impersonation = useImpersonation();

  const isUserPage = location.pathname.includes('/admin/users');

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    resetField,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: USER_PROFILE_FORM_VALIDATION_SCHEMA,
  });

  const isMVUser =
    (readOnly && defaultValues?.role !== CAMPUS_USER) ||
    (readOnly && defaultValues?.role !== INTERNAL_USER);

  const isEditButtonVisible =
    !impersonation.isImpersonating() &&
    isNotNewUser &&
    (isCurrentUserProfile || !readOnly);

  const getUserLabel = () => {
    if (user) {
      return getStatusLabel(
        user?.status === 'ACTIVE' ? 'Active' : 'Locked',
        USERS_PROFILE_STATUSES
      );
    }
  };

  const onCancel = () => {
    if (!user) {
      history.push(redirectPath);
    } else {
      reset(defaultValues);
      setIsFormActive(true);
    }
  };

  const onSubmit = (formData) => {
    setLoader(true);

    onUserFormSubmit({
      formData,
      history,
      redirectPath,
      users,
      user,
      currentUser,
      isCurrentUserProfile,
      reset,
    }).catch(() => {
      setLoader(false);
    });
  };

  useEffect(() => {
    setIsNotNewUser(!!user);
    setRedirectPath(
      isCurrentUserProfile
        ? '/admin/profile'
        : getRedirectPathForUser({ setIsFormActive, user, currentClient })
    );
  }, [user, setIsFormActive]);

  useEffect(() => {
    setIsFormActive && setIsFormActive(!!user);
    setLoading && setLoading(false);
  }, []);

  return loading ? (
    <Loader {...{ loading: loading }} />
  ) : (
    <UserProfileContext.Provider
      value={{ control, errors, clearErrors, resetField }}
    >
      <ResponsiveWrapper>
        <Loader {...{ loading: loader }} />

        {!isCurrentUserProfile && (
          <Breadcrumbs
            breadcrumbs={getBreadcrumbsForUserPage(
              isUserPage,
              currentClient,
              user
            )}
          />
        )}
        <div className="flex items-center justify-between mt-4">
          <Heading variant={isCurrentUserProfile ? 'h2' : 'h1'}>
            {pageName}
          </Heading>

          {isEditButtonVisible && (
            <ActionMenu
              options={actionMenuItems}
              onEditClick={() => setIsFormActive(false)}
              isActionMenuDisabled={!isFormDisabled}
            />
          )}
        </div>

        {!isMVUser && !isCurrentUserProfile ? getUserLabel() : null}

        <FlashNotification customClass="mb-4 mt-0" />

        <UserForm
          {...{
            isMVUser,
            userRole: defaultValues?.role,
            currentClient,
            isFormDisabled,
            isSelectDisabled:
              !!currentClient ||
              isImpersonatingUser ||
              currentUser?.data?.basicUser ||
              defaultValues?.role === CAMPUS_USER ||
              defaultValues?.role === INTERNAL_USER ||
              isMVUser,
            isNotNewUser,
            isCurrentUserProfile,
            onCancel,
            onSubmit,
            handleSubmit,
          }}
        />
      </ResponsiveWrapper>
    </UserProfileContext.Provider>
  );
};

export default withRouter(UserProfile);

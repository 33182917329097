import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import api from 'api';

import CustomButton from 'components/partials/custom-button/custom-button';
import Loader from 'components/partials/loader/loader';
import ReportForm from 'containers/admin/reports/report/report-form';
import toast from 'components/partials/toast/toast';
import Heading from 'components/partials/heading/heading';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import ActionMenu from 'components/partials/action-menu/action-menu';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import { getActionMenuItems } from 'containers/admin/reports/report/report.helpers';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

const AdminReport = ({ reportId }) => {
  const user = useCurrentUser();
  const userId = user?.data?.oktaUserId;

  const history = useHistory();
  const isNew = reportId === 'new';

  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(!isNew);
  const [powerBiReports, setPowerBiReports] = useState([]);
  const [products, setProducts] = useState([]);

  const { control, handleSubmit, reset, setFocus, clearErrors } = useForm({
    defaultValues: {
      title: '',
      description: '',
      powerBiId: '',
      productId: '',
    },
  });

  useEffect(() => {
    setFocus('title');
  }, [disabled]);

  useEffect(() => {
    const fetchPowerBiReports = async () => {
      try {
        const response = await api.getPowerBiReports({ userId });

        if (response?.data) {
          setPowerBiReports(response.data);
        }
      } catch (error) {
        console.error('Error while fetching power bi reports', error);
      }
    };

    fetchPowerBiReports();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.getProducts({ userId });

        if (response?.data) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error('Error while fetching power bi reports', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (!isNew) {
      const fetchReport = async () => {
        setLoading(true);
        try {
          const response = await api.getReport({ userId, reportId });

          if (!response?.data) {
            return;
          }

          const { title, description, powerBiId, productId } = response.data;

          setReportData({ title, description, powerBiId, productId });
          reset({
            title,
            description,
            powerBiId,
            productId,
          });
        } catch (error) {
          console.error('Error while fetching report data', error);
        } finally {
          setLoading(false);
        }
      };

      fetchReport();
    } else {
      setLoading(false);
    }
  }, [reportId, isNew, reset]);

  const onSubmit = async (data) => {
    setLoading(true);

    const transformedData = { ...data, productId: data.productId || undefined };

    try {
      if (isNew) {
        await api.createReport({ userId, reportData: transformedData });
      } else {
        await api.modifyReport({
          userId,
          reportId,
          reportData: transformedData,
        });
      }
      toast.success({
        title: `The report was successfully ${
          reportId ? 'updated' : 'created'
        }`,
      });
      history.push('/admin/reports');
    } catch (error) {
      toast.error({ title: 'Something went wrong' });
      console.error('Error while saving report', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await api.deleteReport({ userId, reportId });
      toast.success({
        title: `The report was successfully removed`,
      });
      history.push('/admin/reports');
    } catch (error) {
      toast.error({ title: 'Something went wrong' });
      console.error('Error while report deleting', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (isNew) {
      history.push('/admin/reports');
    } else {
      setDisabled(true);
      reset(reportData);
    }
  };

  const reportTitle = isNew ? 'New Report' : reportData?.title;

  return (
    <ResponsiveWrapper customClass="my-4">
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Reports', href: '/admin/reports' },
          { label: reportTitle, isActive: true },
        ]}
      />
      <div className="flex items-center justify-between mt-3">
        <Heading variant="h1">{reportTitle}</Heading>

        {!isNew && (
          <ActionMenu
            buttonName="Edit report"
            options={getActionMenuItems({
              handleDelete,
              reportId,
              reportName: reportData?.title,
            })}
            onEditClick={() => setDisabled(false)}
            isActionMenuDisabled={!disabled}
          />
        )}
      </div>

      <Loader loading={loading} fullScreen={false} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ReportForm
          control={control}
          disabled={disabled}
          powerBiReports={powerBiReports}
          products={products}
          clearErrors={clearErrors}
        />

        {(!disabled || isNew) && (
          <div className="mt-4 flex justify-end">
            <CustomButton type="button" variant="text" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton type="submit" variant="primary">
              {isNew ? 'Add Report' : 'Save Changes'}
            </CustomButton>
          </div>
        )}
      </form>
    </ResponsiveWrapper>
  );
};

export default AdminReport;

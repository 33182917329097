import { useSelector } from 'react-redux';

import { useGenericCollection } from 'helpers/state/use-generic-collection';

export const useProducts = () => {
  const collectionMethods = useGenericCollection('product');
  const products = useSelector((state) => state?.products);

  return {
    ...collectionMethods,
    ...products,
  };
};

import React, { useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';
import Heading from 'components/partials/heading/heading';

import { unmountConfirmationModal } from 'helpers/confirmation-render';

const Confirmation = ({
  children,
  onCloseCallback,
  onConfirmCallback,
  headerText,
  onConfirmButtonLabel = 'OK',
  disabled,
  text,
  onCloseModal = () => {},
}) => {
  const onClose = useCallback(() => {
    unmountConfirmationModal();
    if (onCloseCallback) {
      onCloseCallback();
    }
  }, [onCloseCallback]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onClose);

    return () => {
      window.removeEventListener('popstate', onClose);
    };
  }, [onClose]);

  const onConfirm = () => {
    onConfirmCallback();
    onClose();
  };

  const modalWrapperRf = useRef();
  const modalInnerRf = useRef();

  const handleClickOutside = (e) => {
    if (modalInnerRf.current && !modalInnerRf.current.contains(e.target)) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    } else if (e.key === 'Enter') {
      onConfirm();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (modalWrapperRf.current) {
      modalWrapperRf.current.focus();
    }
  }, []);

  return (
    <div
      className="fixed inset-0 z-[701] h-[100vh] max-h-[100vh] overflow-hidden"
      ref={modalWrapperRf}
      tabIndex="-1"
    >
      <div className="absolute inset-0 opacity-60 bg-dark-00" />
      <div className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
        <div
          ref={modalInnerRf}
          className={classNames(
            'mobile:w-[330px] tablet:w-[500px] bg-white p-8 rounded-md overflow-hidden flex flex-col justify-between'
          )}
        >
          <Heading
            variant="h1"
            customClass="mobile:text-center tablet:text-left"
          >
            {headerText}
          </Heading>
          {text && (
            <p className="text-lg mt-1 mobile:text-center tablet:text-left">
              {text}
            </p>
          )}
          {children}
          <div className="flex mobile:flex-col-reverse tablet:flex-row justify-end mt-6 space-y-2 tablet:space-y-0">
            <CustomButton
              type="button"
              onClick={() => {
                onClose();
                onCloseModal();
              }}
            >
              <span className="text-blue-03">Cancel</span>
            </CustomButton>
            <CustomButton
              onClick={onConfirm}
              variant="primary"
              disabled={disabled}
            >
              {onConfirmButtonLabel}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;

import { createRoot } from 'react-dom/client';
import Confirmation from 'components/partials/confirmation/confirmation';

const MODAL_CONTAINER = document.getElementById('modal-container');
let modalRoot = null;

export const renderConfirmationModal = (props) => {
  if (!modalRoot) {
    modalRoot = createRoot(MODAL_CONTAINER);
  }
  modalRoot.render(<Confirmation {...props} />);
};

export const unmountConfirmationModal = () => {
  if (modalRoot) {
    modalRoot.unmount();
    modalRoot = null;
  }
};

import React from 'react';
import Toggle from 'components/partials/toggle/toggle';

const commonStyles = 'pl-4 border-r-2 border-white font-semibold';

export const getIncludedReportsTableSettings = (
  productId,
  handleToggleChange
) => [
  {
    id: 'title',
    accessorKey: 'title',
    header: () => <span>REPORT NAME</span>,
    cell: (info) => info.renderValue(),
    headerClassName: `${commonStyles} cursor-pointer rounded-tl-md rounded-bl-md`,
    className: 'pl-4 tablet:w-[30%] font-semibold',
    enableSorting: true,
    sortingFn: 'alphanumeric',
  },
  {
    id: 'description',
    accessorKey: 'description',
    header: () => <span>DESCRIPTION</span>,
    cell: (info) => <span>{info.renderValue()}</span>,
    headerClassName: `${commonStyles}`,
    className: 'pl-4 tablet:w-[63%]',
    enableSorting: false,
  },
  {
    id: 'linked',
    accessorKey: 'productId',
    header: () => <span>LINKED</span>,
    cell: ({ row: { original: report } }) => {
      const isIncluded = report.productId == productId;

      return (
        <Toggle
          isToggleOn={isIncluded}
          onToggleChange={(newValue) => handleToggleChange(report, newValue)}
        />
      );
    },
    headerClassName: 'h-12 rounded-tr-md rounded-br-md font-semibold',
    className: 'py-3 flex justify-center',
    enableSorting: false,
  },
];

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';

import api from 'api';

import GoalsMatrix from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix';
import GoalsFilters from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-filters/goals-matrix-filters';
import Heading from 'components/partials/heading/heading';

import {
  HEADERS,
  ROWS,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-tab.constants';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

const GoalsTabContent = ({ clientId, selectedYear }) => {
  const history = useHistory();
  const [showOnlyDefined, setShowOnlyDefined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [goals, setGoals] = useState([]);
  const user = useCurrentUser();

  useEffect(() => {
    const fetchGoals = async () => {
      setLoading(true);
      try {
        const res = await api.getAllGoals({
          userId: user?.data?.oktaUserId,
          clientId,
          year: selectedYear,
        });

        if (res?.data?.content) {
          setGoals(res.data.content);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchGoals();
  }, [selectedYear]);

  const handleYearChange = (year) => {
    history.push(`/admin/clients/${clientId}/goals/${year}`);
  };

  return (
    <div className="w-full mt-5">
      <Heading variant="h2" customClass="my-5 block">
        Client enrollment goals for {selectedYear}
      </Heading>
      <GoalsFilters
        showOnlyDefined={showOnlyDefined}
        setShowOnlyDefined={setShowOnlyDefined}
        selectedYear={selectedYear}
        setSelectedYear={handleYearChange}
        customClass="mb-3"
      />
      <GoalsMatrix
        headers={Object.values(HEADERS)}
        rows={Object.values(ROWS)}
        data={goals}
        showOnlyDefined={showOnlyDefined}
        year={selectedYear}
        loading={loading}
      />
    </div>
  );
};

const GoalsTab = ({ clientId, selectedYear }) => (
  <SecureRoute
    exact
    path="/admin/clients/:clientId/goals/:year"
    render={() => (
      <GoalsTabContent clientId={clientId} selectedYear={selectedYear} />
    )}
  />
);

export default GoalsTab;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import Heading from 'components/partials/heading/heading';
import Toggle from 'components/partials/toggle/toggle';
import File from 'containers/admin/clients/client/tabs-components/files-tab/file/file';
import TableComponent from 'components/partials/table-component/table-component';
import Loader from 'components/partials/loader/loader';
import CustomButton from 'components/partials/custom-button/custom-button';

import Notification from 'components/partials/notification/notification.helper';
import { renderConfirmationModal } from 'helpers/confirmation-render';

import { getFileDetailsActionMenuItems } from 'containers/admin/clients/client/tabs-components/files-tab/file/file-action-menu-items';
import { getFileUsersTableSettings } from 'containers/admin/clients/client/tabs-components/files-tab/file/file-page/file-page-table-settings';

import api from 'api';

import { useClients } from 'state/clients/clients.thunk';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { transformFileToFormObject } from 'helpers/forms';

import { ALERTS } from 'containers/admin/clients/client/tabs-components/files-tab/file/file.constants';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

let newUsersAssignedToFile = [];
let usersFromServer = [];

const FilePage = ({ clientId, fileId, notificationParams = {} }) => {
  //loadings
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  //general data
  const [fileData, setfileData] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [displayedData, setDisplayedData] = useState([]);
  //toggle
  const [isToggleOn, setIsToggleOn] = useState(false);
  //selected users
  const [isAllUsersSelected, setIsAllUsersSelected] = useState(false);
  const [isShareBtnDisabled, setIsShareBtnDisabled] = useState(false);

  const currentUser = useCurrentUser();
  const clients = useClients();

  //update file data
  const onFileEdit = (data) => {
    setFormLoading(true);

    const status = data.status || data.alertStatus || 'ACTIVE';

    api
      .updateFile({
        currentUser,
        clientId,
        fileId,
        ...data,
      })
      .then(({ data }) => {
        setIsFormDisabled(true);
        Notification.flash(ALERTS[status].heading, ALERTS[status].variant);

        setfileData(data);
        setIsToggleOn(data.autoAssignToUsers);
      })
      .catch((e) => console.error('Error during updating file.FileDetails', e))
      .finally(() => {
        setFormLoading(false);
      });
  };

  const onToggleChange = (toggled) => {
    const data = {
      autoAssignToUsers: toggled,
      alertStatus: 'UPDATED',
    };

    renderConfirmationModal({
      headerText: `${
        toggled ? 'A' : 'Una'
      }ssign this file to all new users of ${currentClient?.name} ?`,
      text: `All new users for ${currentClient?.name} will ${
        toggled ? '' : 'NOT'
      } have access to this document automatically.`,
      onConfirmCallback: () => onFileEdit(data),
      onConfirmButtonLabel: `Yes, ${
        toggled ? '' : 'un'
      }assign to all new users`,
    });
  };

  //select users to assign file on them
  const onSelectAllUsers = ({ hasAccess }) => {
    newUsersAssignedToFile = displayedData.map((item) => ({
      ...item,
      userId: item.userId,
      hasAccess,
    }));
    setDisplayedData(newUsersAssignedToFile);
    setIsShareBtnDisabled(false);
    setIsAllUsersSelected(hasAccess);
  };

  const onUserChange = (changedUser) => {
    isAllUsersSelected && setIsAllUsersSelected(false);

    //checkin if hasAcces field for thi user was alredy changed
    const user = newUsersAssignedToFile.find(
      (item) => item.userId === changedUser.userId
    );

    user
      ? (user.hasAccess = changedUser.hasAccess) //if so - we change it again
      : newUsersAssignedToFile.push(changedUser); //if not - we push it to the changed users array
    // set new hasAcces fields for rerender
    const newUsersAccesses = displayedData.map((item) =>
      changedUser.userId === item.userId
        ? { ...item, hasAccess: changedUser.hasAccess }
        : { ...item }
    );

    //set new data for rerender
    setDisplayedData(newUsersAccesses);
    newUsersAccesses.every((user) => user.hasAccess === true) &&
      setIsAllUsersSelected(true);
    //enable Share acces btn
    setIsShareBtnDisabled(false);
  };

  const onUsersChangesSubmit = () => {
    const changedUsers = newUsersAssignedToFile.filter((item) =>
      usersFromServer.find(
        (user) =>
          user.userId === item.userId && user.hasAccess !== item.hasAccess
      )
    );

    setFormLoading(true);

    api
      .postFileUsers({ currentUser, clientId, fileId, changedUsers })
      .then(({ data }) => {
        newUsersAssignedToFile = [];
        usersFromServer = data;
        setIsShareBtnDisabled(true);
        Notification.flash(ALERTS.UPDATED.heading, ALERTS.UPDATED.variant);
      })
      .catch((e) => console.error('Error during updating file.fileUsers', e))
      .finally(() => {
        setFormLoading(false);
      });
  };

  const onUsersChangesCancel = () => {
    setDisplayedData(usersFromServer);

    setIsAllUsersSelected(
      usersFromServer.every((user) => user.hasAccess === true)
    );

    setIsShareBtnDisabled(true);
    newUsersAssignedToFile = [];
  };

  //load basic file data
  const loadFile = () => {
    setFormLoading(true);

    api
      .getFile({ fileId, userId: currentUser.data.oktaUserId, clientId })
      .then((response) => {
        setfileData(response?.data);
        setIsToggleOn(response?.data.autoAssignToUsers);
      })
      .catch((error) =>
        console.error('Error during fetching file.FileDetails', error)
      )
      .finally(() => setFormLoading(false));
  };

  const loadClient = () => {
    setLoading(true);
    clients
      .find(clientId)
      .then((client) => {
        setCurrentClient(client.data);
      })
      .catch((error) => {
        console.error('Error during fetching currentClient', error);
      })
      .finally(() => setLoading(false));
  };

  const loadFileUsers = () => {
    setLoading(true);

    api
      .getFileUsers({ fileId, userId: currentUser.data.oktaUserId, clientId })
      .then((response) => {
        usersFromServer = response?.data;
        setDisplayedData(response?.data);

        setIsAllUsersSelected(
          response?.data.every((user) => user.hasAccess === true)
        );
        setIsShareBtnDisabled(true);
      })
      .catch((error) =>
        Notification.flash(
          'Error during fetching File Details',
          'error',
          `We couldn't get list of users because of ${error.status} error. Please try again or contact support.`
        )
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    !!notificationParams &&
      Notification.flash(
        notificationParams.heading,
        notificationParams.variant
      );
    loadClient();
    loadFile();
    loadFileUsers();
  }, []);

  return (
    <>
      {loading || !currentClient?.name || !fileData?.name ? (
        <Loader {...{ loading: loading }} />
      ) : (
        <ResponsiveWrapper>
          <File
            {...{
              pageName: fileData?.name,
              fileData,
              formLoading,
              isFormDisabled,
              setIsFormDisabled,
              onSubmit: onFileEdit,
              actionMenuItems: getFileDetailsActionMenuItems({
                fileName: fileData?.name,
                clientName: currentClient?.name,
                onActionAplied: onFileEdit,
                status: fileData.status,
              }),
              defaultValues: transformFileToFormObject(fileData),
            }}
          />
          <div className="w-full">
            {fileData?.status !== 'ARCHIVED' && !currentUser.data.fdAnalyst && (
              <div>
                <div className="flex items-center justify-between mt-5">
                  <Heading variant="h3" customClass="my-3">
                    File is available to
                  </Heading>

                  {!!displayedData.length && (
                    <Toggle
                      {...{
                        onToggleChange,
                        isToggleOn,
                        toggleText: 'Assign to all new users',
                      }}
                    />
                  )}
                </div>
                {!!displayedData.length ? (
                  <TableComponent
                    columns={getFileUsersTableSettings({
                      onUserChange,
                      onSelectAllUsers,
                      isAllUsersSelected,
                    })}
                    data={displayedData}
                    isLoading={loading}
                    showingRecordsTextIsOn={false}
                    viewByAmountSelectIsOn={false}
                  />
                ) : (
                  <div className="text-md">
                    {!loading && 'No users for this client were found'}
                  </div>
                )}
                {!!displayedData.length && (
                  <div className="flex justify-end mt-6">
                    <CustomButton
                      disabled={isShareBtnDisabled}
                      variant="text"
                      size="md"
                      onClick={onUsersChangesCancel}
                      type="button"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      disabled={isShareBtnDisabled}
                      variant="primary"
                      size="md"
                      type="submit"
                      onClick={() => onUsersChangesSubmit()}
                    >
                      Update access
                    </CustomButton>
                  </div>
                )}
              </div>
            )}
          </div>
        </ResponsiveWrapper>
      )}
    </>
  );
};

export default withRouter(FilePage);

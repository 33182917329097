import React, { forwardRef } from 'react';
import classNames from 'classnames';

import Icons from 'assets/icons';

const Textarea = forwardRef(
  (
    {
      textareaWrapperStyles = '',
      textareaStyles = '',
      label,
      placeholder,
      maxLength = 250,
      onChange,
      disabled,
      value = '',
      labelFor = '',
      rows = 5,
      errorsNames = {},
      helperText,
      required,
      'data-testid': dataTestId = 'textarea-field',
    },
    ref
  ) => {
    const handleChangeValue = (e) => {
      onChange(e.target.value);
    };

    const isValid = Object.keys(errorsNames).length === 0;

    const baseWrapperStyles = `w-full flex flex-col ${textareaWrapperStyles}`;
    const baseStyles = classNames(
      `w-full h-full border border-gray-05 focus-visible:outline-0 rounded-md py-2 px-3 focus:outline-none hover:border-blue-03 focus:border-blue-06 disabled:hover:border-gray-05 transition`,
      textareaStyles,
      {
        'bg-gray-06': disabled,
        'border-red-01 focus:border-red-01 hover:border-red-01': !isValid,
        'border-gray-05': isValid,
      }
    );

    return (
      <div className={baseWrapperStyles}>
        {label && (
          <label
            htmlFor={labelFor}
            className={classNames('font-semibold flex', {
              'mb-2': !helperText,
            })}
          >
            {label}
            {required ? (
              <Icons.RequiredIndicator
                customClass="w-[7px] h-[7px] ml-1 mt-1"
                data-testid={`${dataTestId}-required-indicator`}
              />
            ) : null}
          </label>
        )}

        {helperText && (
          <span className="text-sm mb-1 mt-1.5">{helperText}</span>
        )}
        <textarea
          ref={ref}
          className={baseStyles}
          placeholder={placeholder}
          value={value}
          onChange={handleChangeValue}
          maxLength={maxLength}
          disabled={disabled}
          id={labelFor}
          rows={rows}
        />
        {!isValid && !disabled && (
          <span className="font-sm pt-1 text-red-00 bg-white">
            {errorsNames.message}
          </span>
        )}
        {!disabled && (
          <span className="mt-1 text-sm">
            {value.length} of {maxLength} characters used
          </span>
        )}
      </div>
    );
  }
);

export default Textarea;

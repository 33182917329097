import { combineReducers } from '@reduxjs/toolkit';

import clientsReducer from 'state/clients/clients.slice';
import currentUserReducer from 'state/current-user/current-user.slice';
import filesReducer from 'state/files/files.slice';
import impersonationReducer from 'state/impersonation/impersonation.slice';
import oktaSessionReducer from 'state/okta-session/okta-session.slice';
import reportsReducer from 'state/reports/reports.slice';
import usersReducer from 'state/users/users.slice';
import reportsListReducer from 'state/reports-list/reports-list.slice';
import productsReducer from 'state/products/products.slice';

const rootReducer = combineReducers({
  clients: clientsReducer,
  currentUser: currentUserReducer,
  files: filesReducer,
  impersonation: impersonationReducer,
  reports: reportsReducer,
  session: oktaSessionReducer,
  users: usersReducer,
  adminReports: reportsListReducer,
  products: productsReducer,
});

export default rootReducer;

import React from 'react';
import { Controller } from 'react-hook-form';

import CustomInput from 'components/partials/custom-text-input/custom-text-input';
import Textarea from 'components/partials/textarea/textarea';
import CustomSelect from 'components/partials/custom-select/custom-select';

const ReportForm = ({
  control,
  disabled,
  powerBiReports,
  clearErrors,
  products,
}) => (
  <div className="flex h-full gap-8">
    <div className="flex flex-col gap-3 w-full">
      <Controller
        name="title"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState: { error } }) => (
          <CustomInput
            {...field}
            label="Report name"
            placeholder="Report name..."
            disabled={disabled}
            errorsNames={error}
            labelInTop={true}
            required={true}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState: { error } }) => (
          <Textarea
            {...field}
            label="Description"
            placeholder="Please type report description..."
            disabled={disabled}
            errorsNames={error}
            required={true}
          />
        )}
      />
    </div>

    <div className="flex flex-col gap-3 w-full">
      <Controller
        name="powerBiId"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState: { error } }) => {
          const transformedOptions = powerBiReports.map((report) => ({
            value: report.id,
            label: report.name,
          }));

          return (
            <CustomSelect
              {...field}
              label="PowerBI title"
              disabled={disabled}
              options={transformedOptions}
              labelInTop={true}
              fieldError={
                error && { ...error, message: 'This field is required' }
              }
              required={true}
              onChange={(selectedOption) => {
                field.onChange(selectedOption.value);
              }}
              value={field.value}
              clearInputErr={() => clearErrors('powerBiId')}
            />
          );
        }}
      />

      <Controller
        name="productId"
        control={control}
        render={({ field }) => {
          const transformedOptions = products.map((product) => ({
            value: product.id,
            label: product.name,
          }));

          return (
            <CustomSelect
              {...field}
              label="Associated product"
              disabled={disabled}
              options={transformedOptions}
              onChange={(selectedOption) => {
                field.onChange(selectedOption?.value || '');
              }}
              value={field?.value || ''}
              labelInTop={true}
              isClearable={true}
            />
          );
        }}
      />
    </div>
  </div>
);

export default ReportForm;

import React from 'react';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';
import Loader from 'components/partials/loader/loader';

import ReportAssignmentHeader from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment-header';
import ReportAssignmentRow from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment-row';
import useTableHeight from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/hooks/use-table-height';
import useReportData from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/hooks/use-report-data';
import useReportUsers from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/hooks/use-report-users';
import useAccessActions from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/hooks/use-access-actions';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

/**
 * @param {string} clientId - ID of the client
 * @param {string} productId - ID of the product
 * @param {boolean} [parentLoading=false] - Whether the parent component is loading
 */
const ReportAssignment = ({ clientId, productId, parentLoading = false }) => {
  const { reports, setReports, users, loading, setLoading, userId } =
    useReportData(clientId, productId);

  const { reportUsers, setReportUsers, fetchReportUsers } = useReportUsers(
    users,
    loading,
    setLoading,
    userId,
    clientId
  );

  const {
    handleReportAccessChange,
    handleDataIssueChange,
    handleUserAccessChange,
  } = useAccessActions(
    reports,
    setReports,
    reportUsers,
    setReportUsers,
    userId,
    clientId,
    loading,
    setLoading
  );

  const { tableHeight, tableContainerRef } = useTableHeight([reports.length]);
  const currentUser = useCurrentUser();
  const isInternalAdmin = currentUser?.data?.internalAdmin;

  return (
    <ResponsiveWrapper customClass="relative">
      {!parentLoading && (
        <Loader
          loading={loading}
          customClass="!bg-transparent"
          fullScreen={false}
        />
      )}

      <div className="my-5">
        <Heading variant="h3">Reports Assignment Settings</Heading>
      </div>

      <div className="flex flex-col">
        <ReportAssignmentHeader />

        <div
          ref={tableContainerRef}
          className="overflow-y-auto custom-scroll flex-grow mt-4 pr-1.5"
          style={{ maxHeight: tableHeight }}
        >
          {reports.length === 0 ? (
            <div className="p-6 text-center text-gray-04 border border-gray-05 rounded-md">
              No reports linked yet.
            </div>
          ) : (
            <div className="border rounded-md border-gray-05 overflow-hidden">
              <table className="w-full border-collapse">
                <tbody>
                  {reports.map((report, index) => (
                    <ReportAssignmentRow
                      key={report.id}
                      report={report}
                      fetchReportUsers={fetchReportUsers}
                      onReportAccessChange={handleReportAccessChange}
                      onDataIssueChange={handleDataIssueChange}
                      onUserAccessChange={handleUserAccessChange}
                      reportUsers={reportUsers[report.id]}
                      isFirst={index === 0}
                      isLast={index === reports.length - 1}
                      isLoading={loading}
                      isInternalAdmin={isInternalAdmin}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

export default ReportAssignment;

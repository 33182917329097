import React from 'react';
import { flexRender } from '@tanstack/react-table';
import uniqid from 'uniqid';

import Loader from 'components/partials/loader/loader';

import { getStylesForTable } from 'components/partials/table-component/helpers/get-styles-for-table';
import { getSortIcon } from 'components/partials/table-component/helpers/get-sort-icon';

export const Table = ({
  table,
  sortingParams,
  isLoading,
  isMultiSort,
  customHeight,
}) => (
  <>
    <table className="w-full border-separate border-spacing-0 table-fixed">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={uniqid()}>
            {headerGroup.headers.map((header) => {
              const isColumnSorted = header.column.getSortIndex() >= 0;
              const bgColor = isColumnSorted
                ? 'bg-blue-04 text-white'
                : 'bg-gray-00 text-blue-04';

              const headerPositionClass = isMultiSort
                ? 'justify-between'
                : header.column.columnDef.isHeaderCentered && 'justify-center';

              return (
                <th
                  key={uniqid()}
                  className={`${header.column.columnDef.className} ${bgColor} font-xs ${header.column.columnDef.headerClassName}`}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className={`flex items-center ${headerPositionClass}`}>
                    <div className="w-100 flex justify-center">
                      <div key={uniqid()} className="pr-1">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                      {getSortIcon(
                        sortingParams,
                        header.column.columnDef.id,
                        header.column.columnDef.enableSorting
                      )}
                    </div>
                    {isMultiSort && isColumnSorted && (
                      <div className="pr-4">
                        {header.column.getSortIndex() + 1}
                      </div>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
    </table>

    <div className={`overflow-y-auto mt-2 custom-scrollbar  ${customHeight}`}>
      <table className="w-full border-separate border-spacing-0 ">
        <tbody>
          {table.getRowModel().rows.map((row, rowInd) => (
            <tr className="p-4" key={uniqid()}>
              {row.getVisibleCells().map((cell, cellInd) => {
                const rowsLength = table.getRowModel().rows.length;
                const lastCellInd = row.getVisibleCells().length - 1;

                const styles = getStylesForTable(
                  rowInd,
                  cellInd,
                  rowsLength,
                  lastCellInd
                );

                return (
                  <td
                    className={`${cell.column.columnDef.className} border border-gray-05 ${styles}`}
                    key={uniqid()}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <Loader
      {...{ loading: isLoading }}
      fullScreen={false}
      customClass="!bg-transparent"
    />
  </>
);

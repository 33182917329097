export const ADS_BLOCKS = {
  FINANCE: {
    id: 'financial-aid',
    title: 'Unlock Financial Aid View',
    description:
      'Understand your institution’s pricing power through industry-leading exclusive comparative context.',
    buttonText: 'Speak to Us',
    bgSmall: 'finance-bg-md',
    bgLarge: 'finance-bg-lg',
    type: 'FINANCIAL_AID_VIEW',
  },
  RETENTION: {
    id: 'retention',
    title: 'Unlock Retention View',
    description:
      'Gain insight into your first-year student retention risk for earlier intervention and improved outcomes.',
    buttonText: 'Speak to Us',
    bgSmall: 'retention-bg-md',
    bgLarge: 'retention-bg-lg',
    type: 'RETENTION_VIEW',
  },
};

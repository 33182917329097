import React, { useState } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';

import Icons from 'assets/icons';

import Report from 'containers/user/reports/report-section/report-tile/report-tile';
import Heading from 'components/partials/heading/heading';

const ReportSection = ({
  productType,
  reports,
  dictionary,
  navigateToReport,
  isDefaultOpen,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const getProductTypeDescription = (productType, dictionary) => {
    const entry = Object.values(dictionary).find(
      (item) => item.name === productType
    );

    return entry ? entry.description : productType;
  };

  const getIconForProductType = (productType) => {
    const inconsStyles = 'text-indigo-02 w-5 h-5';

    switch (productType) {
      case 'ADMISSIONS_VIEW':
        return <Icons.Learn customClass={inconsStyles} />;
      case 'FINANCIAL_AID_VIEW':
        return <Icons.Finance customClass={inconsStyles} />;
      case 'RETENTION_VIEW':
        return <Icons.People customClass={inconsStyles} />;
      default:
        return null;
    }
  };

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="my-5">
      <button
        className="flex items-center mb-4 transition-colors duration-200"
        onClick={toggleSection}
      >
        <div className="flex items-center">
          {getIconForProductType(productType)}
          <Heading customClass="!font-bold ml-2" variant="h3">
            {getProductTypeDescription(productType, dictionary) || productType}
          </Heading>
        </div>
        {reports.length && (
          <Icons.Arrow
            className={classNames(
              'text-indigo-02 ml-2 transform transition-all duration-300 ease-in-out',
              { 'rotate-90': isOpen, 'rotate-0': !isOpen }
            )}
          />
        )}
      </button>

      <div
        className={classNames(
          'flex flex-wrap gap-4 overflow-hidden transition-all duration-300 ease-in-out items-stretch',
          {
            'max-h-[2000px] opacity-100 mt-4': isOpen,
            'max-h-0 opacity-0 mt-0': !isOpen,
          }
        )}
      >
        {reports.map((report) => (
          //328px by design
          <div key={uniqid()} className="w-[328px] mb-5">
            <Report
              navigateToReport={() => navigateToReport(report.id)}
              report={report}
              isOpen={isOpen}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportSection;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import api from 'api';

import IncludedReports from 'containers/admin/products/product/included-reports/included-reports';
import CustomButton from 'components/partials/custom-button/custom-button';
import Loader from 'components/partials/loader/loader';
import ProductForm from 'containers/admin/products/product/product-form';
import toast from 'components/partials/toast/toast';
import Heading from 'components/partials/heading/heading';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import ActionMenu from 'components/partials/action-menu/action-menu';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

const AdminProduct = ({ productId }) => {
  const user = useCurrentUser();
  const userId = user?.data?.oktaUserId;
  const history = useHistory();
  const isNew = productId === 'new';

  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(!isNew);
  const [productTypes, setProductTypes] = useState([]);

  const { control, handleSubmit, reset, setFocus, clearErrors } = useForm({
    defaultValues: {
      name: '',
      description: '',
      type: '',
    },
  });

  useEffect(() => {
    setFocus('name');
  }, [disabled, setFocus]);

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await api.dictionary({ userId, type: 'PRODUCT_TYPE' });

        if (response?.data) {
          setProductTypes(response.data);
        }
      } catch (error) {
        console.error('Error while fetching product types', error);
      }
    };

    fetchProductTypes();
  }, [userId]);

  useEffect(() => {
    if (!isNew) {
      const fetchProduct = async () => {
        setLoading(true);
        try {
          const response = await api.getProduct({ userId, productId });

          if (!response?.data) return;
          const { name, description, type } = response.data;

          setProductData({ name, description, type });
          reset({ name, description, type });
        } catch (error) {
          console.error('Error while fetching product data', error);
        } finally {
          setLoading(false);
        }
      };

      fetchProduct();
    } else {
      setLoading(false);
    }
  }, [productId, isNew, reset, userId]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (isNew) {
        await api.createProduct({ userId, productData: data });
        history.push('/admin/products');
      } else {
        await api.modifyProduct({ userId, productId, productData: data });
        setDisabled(true);
      }
      toast.success({
        title: `The product was successfully ${isNew ? 'created' : 'updated'}`,
      });
    } catch (error) {
      toast.error({ title: 'Something went wrong' });
      console.error('Error while saving product', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (isNew) {
      history.push('/admin/products');
    } else {
      setDisabled(true);
      reset(productData);
    }
  };

  const productTitle = isNew ? 'New Product' : productData?.name;

  return (
    <ResponsiveWrapper customClass="my-4">
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Products', href: '/admin/products' },
          { label: productTitle, isActive: true },
        ]}
      />
      <div className="flex items-center justify-between mt-3">
        <Heading variant="h1">{productTitle}</Heading>
        {!isNew && (
          <ActionMenu
            buttonName="Edit product"
            onEditClick={() => setDisabled(false)}
            isActionMenuDisabled={!disabled}
          />
        )}
      </div>

      <Loader loading={loading} customClass="bg-gray-06" />

      <Heading variant="h3" customClass="mt-5 mb-4 block">
        Product details
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ProductForm
          control={control}
          disabled={disabled}
          productTypes={productTypes}
          clearErrors={clearErrors}
        />

        {(!disabled || isNew) && (
          <div className="mt-4 flex justify-end">
            <CustomButton type="button" variant="text" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton type="submit" variant="primary">
              {isNew ? 'Add Product' : 'Save Changes'}
            </CustomButton>
          </div>
        )}
      </form>

      {!isNew && (
        <div className="mt-8">
          <Heading variant="h3">Included reports</Heading>
          <IncludedReports
            productId={productId}
            userId={userId}
            initialLoading={loading}
          />
        </div>
      )}
    </ResponsiveWrapper>
  );
};

export default AdminProduct;

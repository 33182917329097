import React from 'react';
import classNames from 'classnames';
import CustomButton from 'components/partials/custom-button/custom-button';

const AdsBlock = ({
  title,
  description,
  buttonText,
  backgroundClass,
  isFullWidth = false,
  onButtonClick,
}) => (
  <div
    className={classNames('rounded-md p-5 overflow-hidden relative w-full', {
      'md:flex-1': !isFullWidth,
    })}
  >
    <div
      className={classNames(
        'absolute inset-0 bg-cover bg-no-repeat bg-center',
        backgroundClass
      )}
    />

    <div
      className={classNames(
        'relative z-10 flex flex-col text-white text-left h-full',
        {
          'md:items-center md:justify-center md:text-center': isFullWidth,
        }
      )}
    >
      <div
        className={
          ('w-full',
          {
            'md:max-w-[800px] flex flex-col': isFullWidth,
            'pr-20': !isFullWidth,
          })
        }
      >
        <h2 className="text-xl font-bold mb-3">{title}</h2>
        <p className="mb-6">{description}</p>
        <div
          className={classNames({
            'md:flex md:justify-center w-full': isFullWidth,
          })}
        >
          <CustomButton
            onClick={onButtonClick}
            variant="primary"
            size="md"
            customClass="mt-4"
          >
            {buttonText}
          </CustomButton>
        </div>
      </div>
    </div>
  </div>
);

export default AdsBlock;

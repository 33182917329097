import api from 'api/api.config';

import { pathWithCurrentUserId } from 'helpers/api/path-with-client-id';
import { buildQueryParams } from 'helpers/api/build-query-params';
import { pathBuilder } from 'helpers/api/path-builder';

//Calls to the MARKETview BE API live here.

// Generic GET method for searchable/filterable/pageable collection
export const getCollection = (
  type,
  {
    pageSize,
    pageIndex,
    sortBy,
    filters,
    searchTerms,
    forceSearch = false,
    headers,
  }
) => {
  const path = pathBuilder(type, filters);

  let url = pathWithCurrentUserId(path);

  if (searchTerms || forceSearch) url += '/_search';

  return api
    .get(url, {
      headers: headers,
      data: null, // Have to do this to add Content-Type header, which we pass for exports (https://github.com/axios/axios/issues/86)
      params: buildQueryParams({
        pageSize,
        pageIndex,
        sortBy,
        filters,
        searchTerms,
        forceSearch,
      }),
    })
    .then(({ data, headers }) => {
      const totalCount = headers['x-total-count'];
      const pageSize = headers['x-page-size'];
      const pageCount = headers['x-page-count'];

      const numPages = Math.ceil(parseInt(totalCount) / parseInt(pageSize));

      return {
        data: data,
        pagination: {
          page: parseInt(pageCount),
          pageSize: parseInt(pageSize),
          pageCount: numPages,
          totalRecords: parseInt(totalCount),
        },
      };
    });
};

export const getCurrentUser = () => api.get(pathWithCurrentUserId('/account'));

export const createOrUpdate = (type, data) => {
  const apiMethod = data.id ? api.put : api.post;

  return apiMethod(pathWithCurrentUserId(`/${type}`), JSON.stringify(data));
};

// TODO rm -- we don't have delete in the real app
export const deleteRecord = (type, id) =>
  api.delete(pathWithCurrentUserId(`/${type}/${id}`));

export const find = (type, id) =>
  api.get(pathWithCurrentUserId(`/${type}/${id}`));

export const postLoginNotifyBackend = () =>
  api.get('/account/post-login-notify');

export const changePassword = ({ newPassword, oldPassword }) =>
  api.post(
    pathWithCurrentUserId('/users/change-password'),
    JSON.stringify({
      newPassword,
      oldPassword,
    })
  );

export const getReports = () =>
  api.get(pathWithCurrentUserId('/users/reports'));

export const getReportToken = (reportId) =>
  api.post(pathWithCurrentUserId(`/powerbi/token/${reportId}`));

//--- Feature Flags Setting ---
export const getFeatureFlagsSetting = (oktaUserId) =>
  api.get(`/${oktaUserId}/features`);

//--- password reset ---
export const requestPasswordReset = (email) =>
  api.textpost('/account/password-reset', email);

export const resetPassword = ({ key, newPassword }) =>
  api.post(
    '/account/password-reset/new-password',
    JSON.stringify({
      key,
      newPassword,
    })
  );

//--- new user methods ---
export const validateActivationKey = (activationKey) =>
  api.get('/account/activate', {
    params: { key: activationKey }, //`?key=${activationKey}`
  });

//--- files manipulation methods ---
export const activateUserAccount = ({ activationKey, oktaUserId, password }) =>
  api.post(
    '/account/activate',
    JSON.stringify({
      key: activationKey,
      oktaUserId,
      password,
    })
  );

export const uploadFile = ({
  fileName,
  file,
  description,
  currentUser,
  clientId,
}) => {
  const formData = new FormData();

  formData.append('file', file);

  formData.append(
    'asset',
    new Blob(
      [
        JSON.stringify({
          name: fileName.value,
          description,
        }),
      ],
      {
        type: 'application/json',
      }
    )
  );

  return api.post(
    `/${currentUser.data.oktaUserId}/clients/${clientId}/assets`,
    formData
  );
};

export const downloadFile = ({ currentUser, clientId, fileId }) => {
  const urlForDownload = !!clientId
    ? `clients/${clientId}/assets`
    : `user-assets`;

  return api.get(
    `/${currentUser.data.oktaUserId}/${urlForDownload}/${fileId}/download`,
    {
      responseType: 'blob',
    }
  );
};

export const updateFile = ({
  fileName,
  file,
  description,
  currentUser,
  clientId,
  fileId,
  status,
  autoAssignToUsers,
}) => {
  const formData = new FormData();

  file && !file.isFakeFile && formData.append('file', file);

  formData.append(
    'asset',
    new Blob(
      [
        JSON.stringify({
          name: fileName?.value,
          description,
          status,
          autoAssignToUsers,
        }),
      ],
      {
        type: 'application/json',
      }
    )
  );

  return api.patch(
    `/${currentUser.data.oktaUserId}/clients/${clientId}/assets/${fileId}`,
    formData
  );
};

export const getFile = ({ userId, clientId, fileId }) =>
  api.get(`/${userId}/clients/${clientId}/assets/${fileId}`);

export const getFileUsers = ({ userId, clientId, fileId }) =>
  api.get(`/${userId}/clients/${clientId}/assets/${fileId}/users`);

export const postFileUsers = ({
  currentUser,
  clientId,
  fileId,
  changedUsers,
}) =>
  api.post(
    `/${currentUser.data.oktaUserId}/clients/${clientId}/assets/${fileId}/users`,
    JSON.stringify(changedUsers)
  );

export const getNewFilesCount = (oktaUserId) =>
  api.get(`${oktaUserId}/user-assets/count?viewed.equals=false`);

export const notifyFilesAreViewed = (oktaUserId) =>
  api.post(`${oktaUserId}/user-assets/viewed`);

export const getCheckArchivedFile = ({ userId, clientId, search }) =>
  api.get(
    `/${userId}/clients/${clientId}/assets/count?status.equals=ARCHIVED${
      search ? `&q=${search}` : ''
    }`
  );

// Courses
export const getCourseFullData = ({ userId, courseId }) =>
  api.get(`${userId}/cms-meta/courses/${courseId}`);

export const getCoursesFullData = ({ userId }) =>
  api.get(`${userId}/cms-meta/courses`);

export const setLessonProgress = ({ userId, courseId, lessonId, progress }) =>
  api.put(
    `${userId}/cms-meta/courses/${courseId}/lessons/${lessonId}`,
    JSON.stringify({ progress })
  );

export const setCourseStatus = ({ userId, courseId, status }) =>
  api.put(`${userId}/cms-meta/courses/${courseId}`, JSON.stringify({ status }));

export const clearCourseMetadata = ({ userId, courseId }) =>
  api.delete(`${userId}/cms-meta/courses/${courseId}`);

export const dictionary = ({ userId, type }) =>
  api.get(`${userId}/dictionary/${type}`);

import { useState, useCallback } from 'react';
import * as reportAccessService from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.helpers';

/**
 * Custom hook to manage report-user relationships and access
 *
 * @param {Array} users - List of users for which to manage report access
 * @param {boolean} loading - Current loading state
 * @param {Function} setLoading - Function to update loading state
 * @param {string} userId - Current user's ID
 * @param {string} clientId - ID of the client
 * @returns {Object} Object containing:
 *   - reportUsers {Object} - Map of report IDs to arrays of users with access
 *   - setReportUsers {Function} - State setter for reportUsers
 *   - fetchReportUsers {Function} - Function to fetch users for a specific report
 */
export const useReportUsers = (
  users,
  loading,
  setLoading,
  userId,
  clientId
) => {
  const [reportUsers, setReportUsers] = useState({});

  /**
   * Fetches users who have access to a specific report
   *
   * @param {string} reportId - ID of the report to fetch users for
   * @returns {Array|null} Array of users with access status or null if fetch fails
   */
  const fetchReportUsers = useCallback(
    async (reportId) => {
      if (!userId || !reportId || !clientId || loading) return null;

      setLoading(true);
      try {
        const reportUsersResponse = await reportAccessService.fetchReportUsers({
          userId,
          reportId,
          clientId,
        });

        if (reportUsersResponse?.data) {
          const updatedUsers = users.map((user) => ({
            ...user,
            hasAccess: reportUsersResponse.data.some(
              (reportUser) => reportUser.id === user.id
            ),
          }));

          setReportUsers((prev) => ({
            ...prev,
            [reportId]: updatedUsers,
          }));

          return updatedUsers;
        }
      } catch (error) {
        console.error('Error fetching report users:', error);
      } finally {
        setLoading(false);
      }

      return null;
    },
    [userId, clientId, users, loading, setLoading]
  );

  return {
    reportUsers,
    setReportUsers,
    fetchReportUsers,
  };
};

export default useReportUsers;

import { useState } from 'react';
import { ANIMATION_DURATION } from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.constants';

/**
 * Hook to manage expandable/collapsible UI elements with smooth animations
 *
 * @param {Function} fetchData - Function to fetch data when expanding
 * @returns {Object} State and methods for expansion control
 */
export const useExpandable = (fetchData) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  /**
   * Toggle expanded state with appropriate animations
   *
   * @param {string} id - Identifier for the item being expanded/collapsed
   * @param {boolean} hasData - Whether data is already loaded
   */
  const toggle = async (id, hasData) => {
    if (isPending) return;

    if (isExpanded) {
      // Animate closing
      setIsClosing(true);
      setTimeout(() => {
        setIsExpanded(false);
        setIsClosing(false);
      }, ANIMATION_DURATION);
    } else {
      setIsExpanded(true);

      // Fetch data if needed
      if (!hasData && fetchData) {
        setIsPending(true);
        try {
          await fetchData(id);
        } catch (error) {
          console.error('Error loading data:', error);
        } finally {
          setIsPending(false);
        }
      }
    }
  };

  return {
    isExpanded,
    isPending,
    isClosing,
    toggle,
  };
};

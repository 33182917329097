import React, { useState, useEffect } from 'react';
import { SecureRoute } from '@okta/okta-react';
import api from 'api';

import CustomRadioInput from 'components/partials/custom-radio-input/custom-radio-input';
import Heading from 'components/partials/heading/heading';
import TableComponent from 'components/partials/table-component/table-component';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';
import Loader from 'components/partials/loader/loader';
import { getClientProductsTableSettings } from 'containers/admin/clients/client/tabs-components/products-tab/products-tab-table-settings';

import { useProducts } from 'state/products/products.thunk';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

import Icons from 'assets/icons';

const radioOptions = [
  { label: 'Assigned', value: 'assigned' },
  { label: 'All available', value: 'all' },
];

const ProductsTab = ({ clientId }) => {
  const { load: fetchProducts } = useProducts();
  const currentUser = useCurrentUser();
  const isInternalAdmin = currentUser?.data?.internalAdmin;

  const [isLoading, setIsLoading] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const [data, setData] = useState([]);
  const [isErrorOccured, setIsErrorOccured] = useState(false);
  const [filterOption, setFilterOption] = useState('assigned');
  const [clientProducts, setClientProducts] = useState([]);
  const [reports, setReports] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const [sortingParams, setSortingParams] = useState([
    { id: 'name', desc: false },
  ]);

  const handleToggleChange = async (product, newToggleValue) => {
    if (isInternalAdmin) return;

    setIsToggling(true);
    try {
      if (newToggleValue) {
        await api.assignProduct({
          userId: currentUser.data.oktaUserId,
          clientId,
          productId: product.id,
        });
      } else {
        await api.unassignProduct({
          userId: currentUser.data.oktaUserId,
          clientId,
          productId: product.id,
        });
      }

      await fetchClientData();
    } catch (error) {
      console.error('Error during product assignment toggle', error);
    } finally {
      setIsToggling(false);
    }
  };

  const columns = getClientProductsTableSettings(
    clientId,
    reports,
    clientProducts,
    handleToggleChange,
    isInternalAdmin
  );

  const fetchClientData = async () => {
    setIsLoading(true);
    try {
      const [clientProductsResponse, reportsResponse] = await Promise.all([
        api.getClientProducts({
          clientId,
          userId: currentUser.data.oktaUserId,
          sort: `${sortingParams[0].id},${
            sortingParams[0].desc ? 'desc' : 'asc'
          }`,
        }),
        api.getClientReports({
          userId: currentUser.data.oktaUserId,
        }),
      ]);

      if (clientProductsResponse?.data) {
        setClientProducts(clientProductsResponse.data);
      }
      if (reportsResponse?.data) {
        setReports(reportsResponse.data);
      }
    } catch (error) {
      console.error('Error fetching reports/client products', error);
    } finally {
      setIsLoading(false);

      if (initialLoading) {
        setInitialLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchClientData();
  }, [sortingParams]);

  useEffect(() => {
    if (filterOption === 'assigned') {
      return;
    }
    setIsLoading(true);
    fetchProducts({
      sortBy: sortingParams,
      pageSize: 100,
      filters: { clientId },
    })
      .then((response) => {
        setData(response?.data ?? []);
      })
      .catch((e) => {
        console.error('Error fetching products', e);
        setIsErrorOccured(true);
      })
      .finally(() => {
        setIsLoading(false);
        setInitialLoading(false);
      });
  }, [sortingParams, clientId, filterOption]);

  const dataToShow =
    filterOption === 'assigned'
      ? clientProducts
      : data.map((product) => {
          const assignedProduct = clientProducts.find(
            (p) => p.id === product.id
          );

          return assignedProduct
            ? { ...product, assignedDate: assignedProduct.assignedDate }
            : product;
        });

  return (
    <SecureRoute
      exact
      path="/admin/clients/:id/products"
      render={() => (
        <div className="relative">
          <Loader
            loading={initialLoading}
            customClass="!bg-white"
            fullScreen={false}
            overlayClassName="!opacity-0"
          />
          <div className="flex justify-between items-center my-5">
            <Heading variant="h2">Products</Heading>
          </div>
          {!isInternalAdmin && (
            <div className="flex gap-5">
              <div className="font-semibold text-gray-04">Show</div>
              <div className="flex">
                {radioOptions.map((option) => (
                  <CustomRadioInput
                    key={option.value}
                    labelFor={option.value}
                    label={option.label}
                    radioValue={option.value}
                    setAction={(val) => setFilterOption(val)}
                    onChange={(val) => setFilterOption(val)}
                    value={filterOption}
                    customClass="mr-4"
                    labelClass={option.colorClass}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="relative">
            {(!isErrorOccured && !!dataToShow.length) ||
            (isLoading && !initialLoading) ? (
              <TableComponent
                columns={columns}
                data={dataToShow}
                isLoading={isLoading || isToggling}
                sortingParams={sortingParams}
                setSortingParams={setSortingParams}
                customHeight="max-h-[50vh]"
                pagination={false}
              />
            ) : !isLoading && !dataToShow.length ? (
              <EmptySpaceContent
                image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
                text="There are no files to show yet."
              />
            ) : null}
          </div>
        </div>
      )}
    />
  );
};

export default ProductsTab;

import React from 'react';
import CustomButton from 'components/partials/custom-button/custom-button';
import CustomCheckbox from 'components/partials/custom-checkbox/custom-checkbox';
import ReportAssignmentUsers from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment-users';
import Icons from 'assets/icons';

import { getBorderClass } from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.utils';
import { USER_ASSIGNMENT_STATUS } from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.constants';
import { useExpandable } from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/hooks/use-exandable';

const ReportAssignmentRow = ({
  report,
  fetchReportUsers,
  onReportAccessChange,
  onDataIssueChange,
  onUserAccessChange,
  reportUsers,
  isFirst,
  isLast,
  isLoading,
  isInternalAdmin = false,
}) => {
  const { isExpanded, isPending, isClosing, toggle } = useExpandable(
    (reportId) => fetchReportUsers(reportId)
  );

  const handleToggle = () => {
    if (isLoading) return;
    toggle(report.id, !!reportUsers);
  };

  const borderClass = getBorderClass(isFirst, isLast);
  const isDisabled = isLoading || isInternalAdmin;

  return (
    <>
      <tr className="transition-colors duration-200">
        <td className={`py-3 w-[5%] ${borderClass}`}>
          <div className="flex justify-center">
            <CustomButton
              variant="text"
              size="xs"
              onClick={handleToggle}
              iconBefore={
                <div
                  className={`transform transition-transform duration-300 text-blue-03 ${
                    isExpanded ? 'rotate-180' : ''
                  }`}
                >
                  {isExpanded ? (
                    <Icons.OutlinedMinus customClass="w-5 h-5" />
                  ) : (
                    <Icons.OutlinedPlus
                      customClass={`w-5 h-5 ${isPending ? 'opacity-50' : ''}`}
                      fill=""
                    />
                  )}
                </div>
              }
              customClass="p-0"
              disabled={isLoading || isPending}
            />
          </div>
        </td>
        <td className={`py-3 px-4 w-[55%] ${borderClass}`}>
          <span className="font-semibold text-gray-03">{report.title}</span>
        </td>
        <td className={`py-3 px-4 text-center w-[20%] ${borderClass}`}>
          <CustomCheckbox
            indeterminate={
              report.userAssignmentStatus ===
              USER_ASSIGNMENT_STATUS.PARTIALLY_ASSIGNED
            }
            labelFor={`report-access-${report.id}`}
            label=""
            checkboxValue={report.id}
            checked={
              report.userAssignmentStatus ===
              USER_ASSIGNMENT_STATUS.ALL_ASSIGNED
            }
            onChange={() =>
              onReportAccessChange(
                report.id,
                report.userAssignmentStatus !==
                  USER_ASSIGNMENT_STATUS.ALL_ASSIGNED
              )
            }
            disabled={isDisabled}
            customStylesLabel="flex justify-center"
          />
        </td>
        <td className={`py-3 px-4 text-center w-[20%] ${borderClass}`}>
          <CustomCheckbox
            labelFor={`data-issue-${report.id}`}
            label=""
            checkboxValue={report.id}
            checked={report.hasDataIssue}
            onChange={() => onDataIssueChange(report.id, !report.hasDataIssue)}
            disabled={isDisabled}
            customStylesLabel="flex justify-center"
          />
        </td>
      </tr>

      {(isExpanded || isClosing) && reportUsers && (
        <ReportAssignmentUsers
          reportId={report.id}
          reportUsers={reportUsers}
          onUserAccessChange={onUserAccessChange}
          isDisabled={isDisabled}
          isClosing={isClosing}
        />
      )}
    </>
  );
};

export default React.memo(ReportAssignmentRow);

import { Link as RouterLink } from 'react-router-dom';

import CustomButton from 'components/partials/custom-button/custom-button';

import Icons from 'assets/icons';

const commonStyles = 'pl-4 border-r-2 border-white font-semibold';

export const getProductsTableSettings = (productDictionary) => [
  {
    id: 'name',
    accessorKey: 'name',
    header: () => <span>PRODUCT NAME</span>,
    cell: (info) => info.renderValue(),
    headerClassName: `${commonStyles} cursor-pointer rounded-tl-md rounded-bl-md`,
    className: 'pl-4 tablet:w-[25%] sm:w-[30%] font-semibold',
    enableSorting: true,
    sortingFn: 'alphanumeric',
  },
  {
    id: 'description',
    accessorKey: 'description',
    header: () => <span>PRODUCT DESCRIPTION</span>,
    cell: (info) => <span>{info.renderValue()}</span>,
    headerClassName: `${commonStyles} cursor-context-menu`,
    className: 'pl-4 tablet:w-[30%] sm:w-[35%] lg:w-[45%]',
    enableSorting: false,
  },
  {
    id: 'type',
    accessorKey: 'type',
    header: () => <span>PRODUCT TYPE</span>,
    cell: (info) => {
      const category = info.getValue();

      const associatedProduct = productDictionary?.find(
        (el) => el.name === category
      );

      return associatedProduct ? (
        <span>{associatedProduct.description}</span>
      ) : (
        <span className="text-gray-01">Not assigned</span>
      );
    },
    headerClassName: `${commonStyles} cursor-pointer`,
    className: 'pl-4 tablet:w-[25%] sm:w-[15%]',
    enableSorting: true,
  },

  {
    id: 'button-cell',
    cell: ({
      row: {
        original: { id },
      },
    }) => (
      <RouterLink to={`/admin/products/${id}`}>
        <CustomButton
          variant="secondary"
          size="sm"
          iconBefore={<Icons.Eye alt="EyeIcon" height="100%" width="14" />}
          customClass="mr-2 text-xs md:text-sm"
        >
          View Details
        </CustomButton>
      </RouterLink>
    ),
    headerClassName: 'h-12 rounded-tr-md rounded-br-md',
    className: 'py-3 flex justify-center',
  },
];

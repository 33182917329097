import api from 'api/api.config';

export const getProducts = ({ userId }) =>
  api.get(`/${userId}/products?sort=name,asc`);

export const getProduct = ({ userId, productId }) =>
  api.get(`/${userId}/products/${productId}`);

export const createProduct = ({ userId, productData }) =>
  api.post(`/${userId}/products`, JSON.stringify(productData));

export const modifyProduct = ({ userId, productId, productData }) =>
  api.put(`/${userId}/products/${productId}`, JSON.stringify(productData));

export const getClientProducts = ({ userId, clientId, sort }) => {
  const queryString = sort ? `?size=1000&sort=${sort}` : '?size=1000';

  return api.get(`/${userId}/clients/${clientId}/products${queryString}`);
};

export const getClientProduct = ({ userId, clientId, productId }) =>
  api.get(`/${userId}/clients/${clientId}/products/${productId}`);

export const assignProduct = ({ userId, clientId, productId }) =>
  api.put(`/${userId}/clients/${clientId}/products/${productId}`);

export const unassignProduct = ({ userId, clientId, productId }) =>
  api.delete(`/${userId}/clients/${clientId}/products/${productId}`);

import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';
import Toggle from 'components/partials/toggle/toggle';

import { formatDate } from 'helpers/dates-formatter';

import Icons from 'assets/icons';

const commonStyles = 'pl-4 border-r-2 border-white font-semibold h-12';

export const getClientProductsTableSettings = (
  clientId,
  includedReports,
  clientProducts,
  handleToggleChange,
  isInternalAdmin = false
) => [
  {
    id: 'name',
    accessorKey: 'name',
    header: () => <span>PRODUCT NAME</span>,
    cell: (info) => <span>{info.renderValue()}</span>,
    headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-pointer`,
    className: 'pl-4 py-3 tablet:w-[20%] font-semibold',
    enableSorting: true,
  },
  {
    id: 'id',
    accessorKey: 'id',
    header: () => <span>INCLUDED REPORTS</span>,
    cell: (info) => {
      const productId = info.getValue();

      const reports = includedReports.filter(
        (report) => report.productId === productId
      );

      if (!reports.length) {
        return;
      }

      return (
        <span className="w-full text-gray-04">
          {reports.map((report) => report.title).join(', ')}
        </span>
      );
    },
    headerClassName: `${commonStyles} cursor-context-menu`,
    className: 'pl-4 py-3 tablet:w-[40%]',
    enableSorting: false,
  },
  {
    id: 'assignedDate',
    accessorKey: 'assignedDate',
    header: () => <span>ASSIGNED DATE</span>,
    cell: (info) => formatDate(info.row.original.assignedDate, 'MMM D, YYYY'),
    headerClassName: `${commonStyles}`,
    className: 'pl-4 py-3 tablet:w-[15%]',
    enableSorting: false,
  },
  {
    id: 'assigned',
    accessorKey: 'id',
    header: () => <span>ASSIGNMENT</span>,
    cell: ({ row: { original: product } }) => {
      const isAssigned = clientProducts.some((p) => p.id === product.id);

      return (
        <div className="flex justify-center">
          <Toggle
            isToggleOn={isAssigned}
            onToggleChange={(newValue) => handleToggleChange(product, newValue)}
            disabled={isInternalAdmin}
          />
        </div>
      );
    },
    isHeaderCentered: true,
    headerClassName: commonStyles,
    className: 'pl-4 py-3 tablet:w-[12%]',
    enableSorting: false,
  },
  {
    id: 'button-cell',
    accessorKey: 'id',
    header: () => <span></span>,
    cell: (info) => {
      const productId = info.getValue();
      const isAssigned = clientProducts.some(
        (product) => product.id === productId
      );

      return (
        <RouterLink
          to={`/admin/clients/${clientId}/products/${productId}`}
          className={classNames({
            '!pointer-events-none opacity-0': !isAssigned,
          })}
        >
          <CustomButton
            variant="secondary"
            size="sm"
            iconBefore={<Icons.Eye alt="EyeIcon" height="100%" width="14" />}
            customClass="text-xs md:text-sm"
          >
            View Details
          </CustomButton>
        </RouterLink>
      );
    },
    headerClassName: `${commonStyles} rounded-tr-md rounded-br-md`,
    className: 'py-3 flex justify-center items-center',
  },
];

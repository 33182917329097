import * as MARKETviewApi from 'api/market-view-api';
import * as StrapiApi from 'api/strapi-api';
import * as GoalsApi from 'api/goals-api';
import * as WidgetsApi from 'api/widgets-api';
import * as ProductsApi from 'api/products-api';
import * as ReportsApi from 'api/reports-api';

//import * as StrapiApi from 'api/strapi-api.local'; Use this for local development

export default {
  ...MARKETviewApi,
  ...StrapiApi,
  ...GoalsApi,
  ...WidgetsApi,
  ...ProductsApi,
  ...ReportsApi,
};

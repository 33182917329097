import { USER_ASSIGNMENT_STATUS } from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.constants';

/**
 * Determines the appropriate border class based on position in a list
 *
 * @param {boolean} isFirst - Whether the item is first in the list
 * @param {boolean} isLast - Whether the item is last in the list
 * @returns {string} CSS class string for borders
 */
export const getBorderClass = (isFirst, isLast) => {
  if (isFirst && isLast) return '';
  if (isFirst) return 'border-b border-gray-05';
  if (isLast) return 'border-t border-gray-05';

  return 'border-t border-b border-gray-05';
};

/**
 * Determines the user assignment status based on the access status of all users
 *
 * @param {Array} users - Array of user objects with hasAccess property
 * @returns {string} The appropriate status enum value
 */
export const determineUserAssignmentStatus = (users) => {
  if (!users || !users?.length) return USER_ASSIGNMENT_STATUS.NONE_ASSIGNED;

  const allHaveAccess = users.every((user) => user.hasAccess);
  const noOneHasAccess = users.every((user) => !user.hasAccess);

  if (allHaveAccess) return USER_ASSIGNMENT_STATUS.ALL_ASSIGNED;
  if (noOneHasAccess) return USER_ASSIGNMENT_STATUS.NONE_ASSIGNED;

  return USER_ASSIGNMENT_STATUS.PARTIALLY_ASSIGNED;
};

/**
 * Updates a single user's access in a users array and returns the new array
 *
 * @param {Array} users - The current users array
 * @param {string} userId - The ID of the user to update
 * @param {boolean} hasAccess - The new access value
 * @returns {Array} Updated users array
 */
export const updateUserAccess = (users, userId, hasAccess) =>
  users.map((user) => (user.id === userId ? { ...user, hasAccess } : user));

/**
 * Updates all users' access in a users array
 *
 * @param {Array} users - The current users array
 * @param {boolean} hasAccess - The access value to set for all users
 * @returns {Array} Updated users array with all access values set
 */
export const updateAllUsersAccess = (users, hasAccess) =>
  users.map((user) => ({ ...user, hasAccess }));

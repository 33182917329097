import api from 'api/api.config';

export const getClientReports = ({ userId, clientId, productId }) => {
  let url = `/${userId}`;

  if (clientId) {
    url += `/clients/${clientId}`;
  }
  url += '/reports';
  if (productId) {
    url += `?productId.equals=${productId}`;
  }

  return api.get(url);
};

export const getReport = ({ userId, reportId }) =>
  api.get(`/${userId}/reports/${reportId}`);

export const createReport = ({ userId, reportData }) =>
  api.post(`/${userId}/reports`, JSON.stringify(reportData));

export const modifyReport = ({ userId, reportId, reportData }) =>
  api.put(`/${userId}/reports/${reportId}`, JSON.stringify(reportData));

export const deleteReport = ({ userId, reportId }) =>
  api.delete(`/${userId}/reports/${reportId}`);

export const getPowerBiReports = ({ userId }) =>
  api.get(`/${userId}/powerbi/reports?reportExists=false`);

export const getReportUsers = ({ userId, reportId, clientId }) =>
  api.get(`/${userId}/reports/${reportId}/users?clientId.equals=${clientId}`);

export const updateReportAccess = ({ userId, reportId, clientId, value }) =>
  api.put(
    `/${userId}/clients/${clientId}/reports/${reportId}`,
    JSON.stringify(value)
  );

export const assignReportOnUser = ({ userId, reportId, id }) =>
  api.put(`/${userId}/reports/${reportId}/users/${id}`);

export const unassignReportFromUser = ({ userId, reportId, id }) =>
  api.delete(`/${userId}/reports/${reportId}/users/${id}`);

import React, { useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import uniqid from 'uniqid';

import Heading from 'components/partials/heading/heading';
import Loader from 'components/partials/loader/loader';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import CustomTabs from 'components/partials/custom-tabs/custom-tabs';
import Switcher from 'components/partials/switcher/switcher';
import { getStatusLabel } from 'components/partials/table-component/helpers/get-status-label';

import UsersTab from 'containers/admin/clients/client/tabs-components/users-tab';
import ProfileTab from 'containers/admin/clients/client/tabs-components/profile-tab';
import FilesTab from 'containers/admin/clients/client/tabs-components/files-tab/files-tab';
import GoalsTab from 'containers/admin/clients/client/tabs-components/goals-tab/goals-tab';

import { CLIENTS_STATUSES } from 'containers/admin/clients/clients.constants';
import { ClientProfileDeleteBtn } from 'containers/admin/clients/client/client-profile-delete-btn';

import { useClients } from 'state/clients/clients.thunk';
import { useCurrentUser } from 'state/current-user/current-user.thunk';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import ProductsTab from './tabs-components/products-tab/products-tab';

const TABS_KEYS = {
  usersTabId: uniqid(),
  profileTabId: uniqid(),
  goalsTabId: uniqid(),
  filesTabId: uniqid(),
  productsTabId: uniqid(),
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return currentDate.getMonth() >= 6 ? currentYear + 1 : currentYear;
};

const ClientDetails = ({ match: { params } }) => {
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const currentYear = getCurrentYear();

  const currentTab = location?.pathname.split('/')[4] || '';
  const clients = useClients();
  const currentUser = useCurrentUser();

  const clientId = params.id;

  const loadClient = () => {
    clients
      .find(clientId)
      .then((client) => {
        // TODO decide : extract transform function from form and use everywhere? (to convert auth domains, etc.)
        setClient(client.data);
        setLoading(false);
      })
      .catch((error) => {
        // TODO handle error
      });
  };

  useEffect(() => {
    loadClient();
  }, [clientId]);

  //if the collection changes, update our state
  useEffect(() => {
    const client =
      clients.data &&
      clients.data.find((u) => u.id.toString() === clientId.toString());

    if (client) {
      setClient(client);
    }
  }, [clients.data]);

  const getYearFromPath = () => {
    const pathParts = location.pathname.split('/');
    const year = pathParts[5];

    return year ? parseInt(year) : currentYear;
  };

  const selectedYear = getYearFromPath();

  const tabLink = (to) =>
    to === 'goals'
      ? `/admin/clients/${clientId}/${to}/${selectedYear}`
      : `/admin/clients/${clientId}/${to}`;

  const tabsData = [
    {
      key: TABS_KEYS.usersTabId,
      link: tabLink('users'),
      component: <UsersTab {...{ clientId }} />,
      label: 'Users',
    },
    {
      key: TABS_KEYS.filesTabId,
      link: tabLink('files'),
      component: <FilesTab {...{ clientId }} />,
      label: 'Files',
    },
    {
      key: TABS_KEYS.goalsTabId,
      link: tabLink('goals'),
      component: <GoalsTab {...{ clientId, selectedYear }} />,
      label: 'Goals',
    },
    {
      key: TABS_KEYS.productsTabId,
      link: tabLink('products'),
      component: <ProductsTab {...{ clientId }} />,
      label: 'Products',
    },
    {
      key: TABS_KEYS.profileTabId,
      link: tabLink('profile'),
      component: <ProfileTab {...{ client, setClient }} />,
      label: 'Profile',
    },
  ];

  const label = getStatusLabel(client.status, CLIENTS_STATUSES);

  return (
    <ResponsiveWrapper>
      {loading ? (
        <Loader {...{ loading: loading }} />
      ) : (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Clients', href: '/admin/clients' },
              { label: client.name, isActive: true },
              { label: currentTab, isActive: true },
            ]}
          />
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center">
              <Heading variant="h1" customClass="mr-8">
                {client.name}
              </Heading>

              <Switcher
                tabs={tabsData.map(({ link, label }) => ({ link, label }))}
              />
            </div>

            {currentTab === 'profile' && (
              <ClientProfileDeleteBtn
                clients={clients}
                client={client}
                currentUser={currentUser}
              />
            )}
          </div>

          {label}

          <CustomTabs options={tabsData} />
        </>
      )}
    </ResponsiveWrapper>
  );
};

export default withRouter(ClientDetails);

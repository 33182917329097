import React, { useEffect, useRef, useState } from 'react';
import CustomCheckbox from 'components/partials/custom-checkbox/custom-checkbox';

const ReportAssignmentUsers = ({
  reportId,
  reportUsers,
  onUserAccessChange,
  isDisabled,
  isClosing,
}) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  // Manage height for smooth animations
  useEffect(() => {
    if (!contentRef.current) return;

    if (isClosing) {
      setHeight(contentRef.current.scrollHeight);
      const timer = setTimeout(() => setHeight(0), 10);

      return () => clearTimeout(timer);
    } else if (reportUsers) {
      // Small delay to ensure content is rendered before measuring
      const timer = setTimeout(
        () => setHeight(contentRef.current.scrollHeight),
        10
      );

      return () => clearTimeout(timer);
    }
  }, [reportUsers, isClosing]);

  useEffect(() => {
    if (reportUsers && contentRef.current && !isClosing) {
      setHeight(contentRef.current.scrollHeight);
    }

    return () => {
      setHeight(0);
    };
  }, []);

  if (!reportUsers && !isClosing) {
    return null;
  }

  return (
    <tr>
      <td colSpan="4" className="p-0 overflow-hidden">
        <div
          className="transition-all duration-300 ease-in-out"
          style={{
            maxHeight: `${height}px`,
            opacity: height > 0 ? 1 : 0,
          }}
        >
          <div ref={contentRef}>
            <table className="w-full">
              <tbody>
                {reportUsers &&
                  reportUsers.map((user, index) => {
                    const isLastUser = index === reportUsers.length - 1;
                    const borderClass = isLastUser
                      ? 'py-2 px-4'
                      : 'border-b border-gray-05 py-2 px-4';

                    return (
                      <tr
                        key={user.id}
                        className="transition-colors duration-200"
                      >
                        <td className={`w-[5%] ${borderClass}`}></td>
                        <td className={`w-[55%] ${borderClass}`}>
                          <div className="text-gray-03">
                            {`${user.firstName} ${user.lastName}`}
                          </div>
                          <div className="text-gray-04 text-sm">
                            {user.jobTitle}
                          </div>
                        </td>
                        <td className={`text-center w-[20%] ${borderClass}`}>
                          <CustomCheckbox
                            labelFor={`user-access-${reportId}-${user.id}`}
                            label=""
                            checkboxValue={user.id}
                            checked={user.hasAccess}
                            onChange={(userData) =>
                              onUserAccessChange(reportId, userData)
                            }
                            disabled={isDisabled}
                            customStylesLabel="flex justify-center"
                          />
                        </td>
                        <td className={`w-[20%] ${borderClass}`}></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default React.memo(ReportAssignmentUsers);

import { useState, useEffect } from 'react';
import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useUsers } from 'state/users/users.thunk';
import * as reportAccessService from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment.helpers';

/**
 * Custom hook to fetch and manage reports and users data
 *
 * @param {string} clientId - ID of the client to fetch reports for
 * @param {string} productId - ID of the product to fetch reports for
 * @returns {Object} Object containing:
 *   - reports {Array} - List of fetched reports
 *   - setReports {Function} - State setter for reports
 *   - users {Array} - List of users for the client
 *   - loading {boolean} - Loading state indicator
 *   - setLoading {Function} - State setter for loading
 *   - userId {string} - Current user ID
 */
export const useReportData = (clientId, productId) => {
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentUser = useCurrentUser();
  const { load: loadUsers } = useUsers();
  const userId = currentUser?.data?.oktaUserId;

  useEffect(() => {
    if (!userId || !clientId || !productId) return;

    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const reportsResponse = await reportAccessService.fetchClientReports({
          userId,
          clientId,
          productId,
        });

        if (reportsResponse?.data) {
          setReports(reportsResponse.data);
        }

        const usersResponse = await loadUsers({
          pageSize: 100,
          filters: { clientId },
        });

        if (usersResponse?.data) {
          setUsers(usersResponse.data);
        }
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [userId, clientId, productId]);

  return {
    reports,
    setReports,
    users,
    loading,
    setLoading,
    userId,
  };
};

export default useReportData;

import React from 'react';
import classNames from 'classnames';
import Icons from 'assets/icons';
import NavButton from 'containers/user/home/components/shared/navigation-button';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

const ReportTile = ({ report, navigateToReport, isOpen }) => {
  const { title, description, hasDataIssue } = report;
  const currentUser = useCurrentUser();
  const isUnavailable = hasDataIssue && !currentUser?.data?.internalUser;

  const handleReportClick = () => {
    if (!isUnavailable) {
      navigateToReport();
    }
  };

  const tabIndex = isOpen ? '0' : '-1'; // We need this for right focus management (we use opacity instead of display: none)

  return (
    <button
      className={classNames(
        'w-full h-full flex flex-col text-left border border-gray-05 rounded-md p-5 transition-all duration-200 hover:shadow-sm',
        { 'hover:shadow-none': isUnavailable }
      )}
      onClick={handleReportClick}
      disabled={isUnavailable}
      tabIndex={tabIndex}
    >
      <div
        className={classNames('font-semibold mb-1 text-gray-03', {
          'opacity-50': isUnavailable,
        })}
      >
        {title}
      </div>

      <p
        className={classNames('text-sm mb-6 text-gray-04', {
          'opacity-50': isUnavailable,
        })}
      >
        {description}
      </p>

      {isUnavailable && (
        <div className="mt-auto">
          <div className="flex items-center text-red-00 mb-2">
            <Icons.Warning className="mr-2" />
            <span className="text-sm font-bold">
              Report incomplete due to missing data.
            </span>
          </div>

          <NavButton
            text="Contact support"
            link="mailto:clientsupport@marketviewedu.com"
          />
        </div>
      )}
    </button>
  );
};

export default ReportTile;

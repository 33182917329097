export const ROLES = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  FD_PRINCIPAL: 'ROLE_FD_PRINCIPAL',
  FD_CLIENT_SERVICE: 'ROLE_FD_CLIENT_SERVICE',
  FD_ANALYST: 'ROLE_FD_ANALYST',
  USER: 'ROLE_USER',
  INTERNAL_USER: 'ROLE_INTERNAL_USER',
};

export const ROLE_NAMES = Object.values(ROLES);

export const buildQueryParams = ({
  pageSize,
  pageIndex = 0,
  sortBy,
  searchTerms,
  forceSearch,
  filters,
}) => {
  const query = new URLSearchParams();

  if (pageSize) query.append('size', pageSize);
  query.append('page', pageIndex);

  if (!!sortBy?.length) {
    sortBy.map((sortParams) => {
      const sortDir = sortParams.desc ? 'desc' : 'asc';

      query.append('sort', `${sortParams.id},${sortDir}`);
    });
  }

  if (searchTerms || forceSearch) query.append('q', searchTerms || '');

  // Filters
  if (filters) {
    Object.keys(filters).forEach((field) => {
      const filterValue = filters[field];

      if (Array.isArray(filterValue) && filterValue.length > 0) {
        query.append(`${field}.in`, filterValue.join(','));
      } else if (typeof filterValue === 'object' && filterValue !== null) {
        // Check if it's the special 'search' case with 'value' property
        if (field === 'search' && 'value' in filterValue && filterValue.value) {
          query.append('q', filterValue.value);
        }
        // Otherwise treat all objects (including search) as field operators
        else {
          Object.keys(filterValue).forEach((operator) => {
            // Only add parameters with valid values
            const operatorValue = filterValue[operator];

            if (operatorValue) {
              query.append(`${field}.${operator}`, operatorValue);
            }
          });
        }
      } else if (!!filterValue && field !== 'search') {
        query.append(`${field}.equals`, filterValue);
      }
    });
  }

  return query;
};

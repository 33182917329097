import React, { forwardRef, useEffect, useRef } from 'react';
import classNames from 'classnames';

const CustomCheckbox = forwardRef(
  (
    {
      labelFor,
      label,
      checkboxValue,
      onChange,
      checked,
      indeterminate,
      customStylesLabel,
      disabled,
      ...rest
    },
    ref
  ) => {
    const defaultRef = useRef(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate, resolvedRef]);

    return (
      <label
        htmlFor={labelFor}
        className={classNames('flex items-center', customStylesLabel)}
      >
        <input
          data-testid={rest['data-testid'] || 'custom-checkbox'}
          className={classNames(
            'w-4 h-4 checked:accent-indigo-02 indeterminate:accent-indigo-02',
            { 'cursor-pointer': !disabled }
          )}
          type="checkbox"
          id={labelFor}
          aria-labelledby="custom-checkbox"
          value={checkboxValue}
          checked={checked}
          onChange={() => {
            if (!disabled) {
              onChange({ userId: checkboxValue, hasAccess: !checked });
            }
          }}
          disabled={disabled}
          ref={resolvedRef}
          {...rest}
        />
        <span className="ml-2">{label}</span>
      </label>
    );
  }
);

export default CustomCheckbox;

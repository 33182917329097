export const getBreadcrumbsForProduct = (currentClient, productName) => {
  const baseBreadcrumbs = [
    { label: 'Clients', href: '/admin/clients' },
    {
      label: currentClient?.name,
      href: `/admin/clients/${currentClient?.id}`,
    },
    {
      label: 'Products',
      href: `/admin/clients/${currentClient?.id}/products`,
    },
    {
      label: productName,
      isActive: true,
    },
  ];

  return baseBreadcrumbs;
};

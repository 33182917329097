import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'api';

import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import Heading from 'components/partials/heading/heading';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import CustomButton from 'components/partials/custom-button/custom-button';
import Loader from 'components/partials/loader/loader';
import ProductDetails from 'containers/admin/clients/client/tabs-components/products-tab/product/product-details';
import ReportsAssignmentSettings from 'containers/admin/clients/client/tabs-components/products-tab/product/report-assignment/report-assignment';
import toast from 'components/partials/toast/toast';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useClients } from 'state/clients/clients.thunk';

import Icons from 'assets/icons';

import { getBreadcrumbsForProduct } from 'containers/admin/clients/client/tabs-components/products-tab/product/product.helpers';
import { renderConfirmationModal } from 'helpers/confirmation-render';

const ClientProduct = ({ clientId, productId }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const [product, setProduct] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);

  const clients = useClients();
  const currentUser = useCurrentUser();
  const userId = currentUser.data.oktaUserId;
  const isInternalAdmin = currentUser?.data?.internalAdmin;

  const handleUnassignProduct = async () => {
    setLoading(true);
    try {
      await api.unassignProduct({ userId, clientId, productId });

      history.push(`/admin/clients/${clientId}/products`);
      toast.success({
        title: `Product ${product.name} was successfully unassigned`,
      });
    } catch (error) {
      console.error('Error during unassigning product', error);
      toast.error({ title: 'Error during unassigning product' });
    } finally {
      setLoading(false);
    }
  };

  const handleUnassignButtonClick = () => {
    renderConfirmationModal({
      headerText: `Are you sure you want to unassign this product from the ${currentClient.name}?`,
      onConfirmCallback: handleUnassignProduct,
      onConfirmButtonLabel: 'Yes, unassign product',
    });
  };

  useEffect(() => {
    setLoading(true);
    if (clientId) {
      clients
        .find(clientId)
        .then((client) => {
          setCurrentClient(client.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error during fetching client.NewUserPage', error);
          setLoading(false);
        });
    }
  }, [clientId]);

  useEffect(() => {
    const fetchProduct = async () => {
      setInitialLoading(true);
      try {
        const response = await api.getClientProduct({
          userId,
          clientId,
          productId,
        });

        if (!response?.data) {
          throw new Error('No product data available');
        }

        setProduct(response.data);
      } catch (error) {
        console.error('Error while fetching product data', error);
        history.push(`/admin/clients/${clientId}/products`);
        toast.error({ title: 'Error loading product' });
      } finally {
        setInitialLoading(false);
      }
    };

    fetchProduct();
  }, [productId, userId, clientId]);

  return (
    <ResponsiveWrapper>
      <Loader loading={initialLoading} />
      {product && currentClient && (
        <>
          <Breadcrumbs
            breadcrumbs={getBreadcrumbsForProduct(currentClient, product?.name)}
          />

          <div className="flex items-center justify-between mt-4 mb-5">
            <Heading variant="h1">{product?.name}</Heading>
            <CustomButton
              variant="secondary"
              onClick={handleUnassignButtonClick}
              iconBefore={<Icons.Unassign customClass="size-5" />}
              loading={loading && !initialLoading}
              disabled={loading || isInternalAdmin}
            >
              Unassign Product
            </CustomButton>
          </div>
          <div className="flex items-baseline mb-5 gap-12">
            <ProductDetails
              description={product?.description}
              customClass="w-1/3"
            />
            <ReportsAssignmentSettings
              clientId={clientId}
              productId={productId}
              parentLoading={loading || initialLoading}
            />
          </div>
        </>
      )}
    </ResponsiveWrapper>
  );
};

export default ClientProduct;

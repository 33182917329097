import api from 'api';

/**
 * Fetches reports for a client/product
 *
 * @param {string} params.userId - Current user ID
 * @param {string} params.clientId - Client ID
 * @param {string} params.productId - Product ID
 * @returns {Promise} API response with reports data
 */
export const fetchClientReports = async ({ userId, clientId, productId }) =>
  api.getClientReports({ userId, clientId, productId });

/**
 * Fetches users with access to a specific report
 *
 * @param {string} params.userId - Current user ID
 * @param {string} params.reportId - Report ID
 * @param {string} params.clientId - Client ID
 * @returns {Promise} API response with report users data
 */
export const fetchReportUsers = async ({ userId, reportId, clientId }) =>
  api.getReportUsers({ userId, reportId, clientId });

/**
 * Updates report access settings
 *
 * @param {string} params.userId - Current user ID
 * @param {string} params.clientId - Client ID
 * @param {string} params.reportId - Report ID
 * @param {Object} params.value - Values to update
 * @returns {Promise} API response
 */
export const updateReportAccess = async ({
  userId,
  clientId,
  reportId,
  value,
}) => api.updateReportAccess({ userId, clientId, reportId, value });

/**
 * Grants report access to a user
 *
 * @param {string} params.userId - Current user ID
 * @param {string} params.reportId - Report ID
 * @param {string} params.id - User ID to grant access to
 * @returns {Promise} API response
 */
export const assignReportToUser = async ({ userId, reportId, id }) =>
  api.assignReportOnUser({ userId, reportId, id });

/**
 * Removes report access from a user
 *
 * @param {string} params.userId - Current user ID
 * @param {string} params.reportId - Report ID
 * @param {string} params.id - User ID to remove access from
 * @returns {Promise} API response
 */
export const unassignReportFromUser = async ({ userId, reportId, id }) =>
  api.unassignReportFromUser({ userId, reportId, id });

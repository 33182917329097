export const pathBuilder = (type, filters) => {
  switch (type) {
    case 'assets':
      return !!filters?.clientId
        ? `/clients/${filters?.clientId}/${type}`
        : '/user-assets';

    case 'files':
      return !!filters?.clientId
        ? `/clients/${filters?.clientId}/assets`
        : '/user-assets';

    case 'adminReports':
      return `/reports`;

    default:
      return `/${type}`;
  }
};

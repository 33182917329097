import React from 'react';
import Heading from 'components/partials/heading/heading';

const ProductDetails = ({ description, customClass }) => (
  <div className={customClass}>
    <Heading variant="h3" customClass="mb-4 block">
      Product Details
    </Heading>
    <p className="text-gray-03 break-words">{description}</p>
  </div>
);

export default ProductDetails;
